import * as yup from 'yup';

const phoneRegExp =
    /^([+][2][5][1]([7]|[9])[0-9]{8}$)|[+][2][5][1][9][0-9]{8}$/;

export const schema = yup
    .object({
        title: yup.string().required('Title  is required'),
        description: yup.string().required('Description  is required'),
        start_date: yup
            .date()
            .typeError('must be a valid date')
            .required('Start date  is required'),
        end_date: yup
            .date()
            .typeError('must be a valid date')
            .required('End date  is required'),
    })
    .required();
