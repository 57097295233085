import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import {authHeaderService} from 'services/auth/auth-header';
import {history} from 'services/auth/history';

import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import { baseUrl } from 'config/constants';


export const axiosBaseQuery =
    (
        {baseUrl, auth}: {baseUrl: string; auth: string} = {
            baseUrl: '',
            auth: '',
        },
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
        },
        unknown,
        unknown
    > =>
    async ({url, method, data, params}) => {
        try {
            const config: AxiosRequestConfig = {
                url: baseUrl + url,
                method,
                data,
                headers: {'x-access-token': auth},
                params: params,
                // withCredentials: true,
            };
            const result = await axios(config);
            return {data: result.data};
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            if (err.response?.status === 401) getRefreshToken();
            if(err.response?.status === 403) {
               
                localStorage.clear();
            } 
            return {
                error: {status: err.response?.status, data: err.response?.data},
            };
        } finally {
        }
    };

const getRefreshToken = async () => {
    const refreshToken: any = jwt_decode(authHeaderService.refreshToken);
    const isExpired = dayjs.unix(refreshToken.exp).diff(dayjs()) < 1;
    if (!isExpired) {
        const response = await axios.get(`${baseUrl}auth/refresh`, {
            headers: {'x-refresh-token': authHeaderService.refreshToken},
        });
        localStorage.setItem('token', JSON.stringify(response.data.token));
        history.go(0);
    } else {
        history.push('/login');
        history.go(0);
    }
};
