import {Configuration} from 'features/model/Configurtion';
import {VehicleCategory} from 'features/model/vehicle-category';
import * as yup from 'yup';

export const schema: yup.SchemaOf<Configuration> = yup
    .object()
    .shape({
        radius: yup
            .object()
            .shape({
                taxi: yup
                    .number()
                    .min(1, 'must be greater then or equal to 1')
                    .typeError('you must specify a number')
                    .required('Radius for taxi is required'),
                // truck: yup
                //     .number()
                //     .min(1, 'must be greater then or equal to 1')
                //     .typeError('you must specify a number')
                //     .required('Radius for truck is required'),
            })
            .required(),
        award_point: yup
            .object()
            .shape({
                taxi: yup
                    .number()
                    .min(1, 'must be greater then or equal to 1')
                    .typeError('you must specify a number')
                    .required('Award point for taxi is required'),
                // truck: yup
                //     .number()
                //     .min(1, 'must be greater then or equal to 1')
                //     .typeError('you must specify a number')
                //     .required('Award point for truck is required'),
            })
            .required(),
       
    })
    .required();

export const VehicleCategorySchema: yup.SchemaOf<VehicleCategory> = yup
    .object()
    .shape({
        name: yup.string().required('name is required'),
        type: yup.string().required('type is required'),
        // commission: yup
        //     .number()
        //     .min(0, 'must be greater then or equal to 1')
        //     .typeError('you must specify a number')
        //     .required('commission is required'),
        // initial_fare: yup
        //     .number()
        //     .min(0, 'must be greater then or equal to 0')
        //     .typeError('you must specify a number')
        //     .required('initial fare is required'),
        // discount: yup
        //     .number()
        //     .min(0, 'must be greater then or equal to 0')
        //     .typeError('you must specify a number')
        //     .required('discount is required'),
        // per_kilometer_cost: yup
        //     .number()
        //     .min(0, 'must be greater then or equal to 0')
        //     .typeError('you must specify a number')
        //     .required('per kilometer cost is required'),
        // per_minute_cost: yup
        //     .number()
        //     .min(0, 'must be greater then or equal to 0')
        //     .typeError('you must specify a number')
        //     .required('per minute cost is required'),
        description: yup.string(),
        capacity: yup.string().required('capacity is required'),
    })
    .required();
