export const DriverCancelationReason = [
    "Youth Asked To Cancel",
    "The Youth Is Unreachable",
    "Bad Pickup Location",
    "Bad DropOff Location",
    "Too Far",
    "Other"
];

export const CustomerCancelationReason = [
    'Volunteer Asked To Cancel',
    'Accidental Request',
    'Wrong Pickup Location',
    'Wrong Drop Off Location',
    'Long Waiting Time',
    "Time out"
];
