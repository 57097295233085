import {useEffect, useState} from 'react';

const litresPerKM = 1 / 10;
const gasLitreCost = 35;

type DistanceProps = {
    leg: any;
    estimatedCost: (cost: any) => void;
    estimatedDistance: (cost: string) => void;
    estimatedDuration: (cost: string) => void;
    selectedVehicle: any;
};
const Distance = (props: DistanceProps) => {
    const [cost, setCost] = useState<number>();
    const [initialFar, setInitialFar] = useState<number>(
        props.selectedVehicle.initial_fare,
    );
    const [constPerKm, setConstPerKm] = useState<number>(
        props.selectedVehicle.per_kilometer_cost,
    );
    const [costPerMinute, setCostPerMinute] = useState<number>(
        props.selectedVehicle.per_minute_cost,
    );
    const [distance, setDistance] = useState<number>(
        props.leg.distance.value / 1000,
    );
    const [duration, setDuration] = useState<number>(+props.leg.duration.value);

    useEffect(() => {
        setDistance(props.leg.distance.value / 1000);
        setDuration(+props.leg.duration.value);
        setCostPerMinute(props.selectedVehicle.per_minute_cost);
        setConstPerKm(props.selectedVehicle.per_kilometer_cost);
        setInitialFar(props.selectedVehicle.initial_fare);
    }, [props]);
    useEffect(() => {
        const cos = Math.floor(
            initialFar +
                constPerKm * distance +
                costPerMinute * (duration / 60),
        );
        setCost(cos);
        props.estimatedDistance(props.leg.distance.text);
        props.estimatedDuration(props.leg.duration.text);
    }, [props, duration, distance, costPerMinute, constPerKm, initialFar]);

    useEffect(() => {
        props.estimatedCost(cost);
    }, [cost]);
    if (!props.leg.distance || !props.leg.duration) return null;

    return (
        <div>
            <p>
                Estimated Time:{' '}
                <span className='fw-bold'>{props.leg.duration.text}</span>
            </p>

            <p>
                Distance:{' '}
                <span className='fw-bold'>{props.leg.distance.text}</span>
            </p>
            {/* <p>
                Cost:{' '}
                <span className='fw-bold'>
                    {cost} {'$'}
                </span>
                .
            </p> */}
        </div>
    );
};
export default Distance;
