import {RoleTypes} from 'features/model/role';
import {User, UserTypes} from 'features/model/user';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Spinner} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';
import {userService} from 'services/auth/user-services';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';

import {
    useActivateUserMutation,
    useBlockUserMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
} from '../api/user-api-services';
import {schema} from './from-validation';

interface UserFormProps {
    viewMode: string;
    title: string;
    selectedItem?: any;
    selectedItemLoading?: boolean;
    userId?: string
}
function UserForm(props: UserFormProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [addNewUser, {isLoading: creating}] = useCreateUserMutation();
    const [updateUser, {isLoading: updating}] = useUpdateUserMutation();
    const [activateUser, {isLoading: activating}] = useActivateUserMutation();
    const [blockUser, {isLoading: blocking}] = useBlockUserMutation();
    const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
    const [enabled, setEnabled] = useState(props?.selectedItem?.enabled);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [selectedValue, setSelectedValue] = useState<any[]>([]);
    const [dirty, setDirty] = useState<boolean>(false);

    const user = userService.currentUser;

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const onCreate = (newData: any) => {
        const sendData = {
            ...newData,
            roles: selectedValue.map((v) => v.value),
        };
        if (selectedValue.length > 0) addNewUser(sendData);
    };
    const onUpdate = (updatedData: any) => {
        const sendData = {
            ...updatedData,
            roles: selectedValue.map((v) => v.value),
        };
        if (selectedValue.length > 0) updateUser(sendData);
    };
    const onDelete = () => {};

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        watch,
        formState: {errors},
    } = useForm<User>({
        defaultValues: {
            is_active: true,
        },
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const onActivate = () => {
        activateUser(props.selectedItem.id);
        setEnabled((prevState) => !prevState);
        setShouldRedirect(true);
    };

    const onBlock = () => {
        blockUser(props.selectedItem.id);
        setEnabled((prevState) => !prevState);
        setShouldRedirect(true);
    };

    useEffect(() => {
        if (props.selectedItem) {
            reset(props.selectedItem);
        }
        setIsLoading(props?.selectedItemLoading);
    }, [props.selectedItem, reset, props.selectedItemLoading]);

    const roleList = [
        {
            value: 'Operator',
            label: 'Operator',
        },
        // {
        //     value: 'FinanceOfficer',
        //     label: 'FinanceOfficer',
        // },
        {
            value: 'Admin',
            label: 'Admin',
        },
        {
            value: 'SuperAdmin',
            label: 'SuperAdmin',
        },
        // {
        //     value: 'BankTeller',
        //     label: 'BankTeller',
        // },
    ];

    const handleOnChange = (e) => {
        setDirty(true);
        setSelectedValue(e);
    };
    useEffect(() => {
        if (Object.keys(errors).length > 0) setDirty(true);
    }, [errors]);
    useEffect(() => {
        if (props.selectedItem?.roles?.length > 0) {
            setSelectedValue(() => {
                return props.selectedItem?.roles.map((element) => {
                    return {
                        value: element,
                        label: element,
                    };
                });
            });
        }
    }, [props.selectedItem?.roles]);

    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> {props.title}</div>
                        <div className='d-flex'>
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form
                            onSubmit={
                                props.viewMode === 'detail'
                                    ? handleSubmit(onUpdate)
                                    : handleSubmit(onCreate)
                            }
                        >
                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                First Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.first_name
                                                    ? true
                                                    : false
                                            }
                                            {...register('first_name')}
                                            type='text'
                                            placeholder='enter First Name'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.first_name?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Last Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.last_name ? true : false
                                            }
                                            {...register('last_name')}
                                            placeholder='enter last name'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.last_name?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Email
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.email ? true : false
                                            }
                                            {...register('email')}
                                            type='text'
                                            placeholder='enter email'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.email?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Phone Number
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.phone_number
                                                    ? true
                                                    : false
                                            }
                                            {...register('phone_number')}
                                            placeholder='enter phone number'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.phone_number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                User Type
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            isInvalid={
                                                errors?.user_type ? true : false
                                            }
                                            {...register('user_type')}
                                        >
                                            <option value=''>
                                                Select user type
                                            </option>
                                            {UserTypes.map((user: string) => {
                                                return (
                                                    <option
                                                        key={user}
                                                        value={user}
                                                    >
                                                        {user}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.user_type?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Gender
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            isInvalid={
                                                errors?.gender ? true : false
                                            }
                                            {...register('gender')}
                                        >
                                            <option value=''>
                                                Select gender
                                            </option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.gender?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group
                                        className='mb-3'
                                        controlId='formEmergencyContactNumber'
                                    >
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Emergency contact number
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            {...register('emergency_contact')}
                                            type='text'
                                            isInvalid={
                                                errors?.emergency_contact
                                                    ? true
                                                    : false
                                            }
                                            placeholder='enter emergence contact number'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.emergency_contact?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Roles
                                            </Form.Label>
                                        </div>

                                        <Select
                                            isMulti
                                            isClearable
                                            isSearchable={false}
                                            className='react-dropdown'
                                            classNamePrefix='dropdown'
                                            options={roleList}
                                            value={selectedValue}
                                            onChange={handleOnChange}
                                        />

                                        <p style={{color: 'red'}}>
                                            {dirty &&
                                                selectedValue.length === 0 &&
                                                'role is Required'}
                                        </p>

                                        {''}

                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.roles &&
                                                errors?.roles[0]?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-100 mx-2'>
                                    <Card>
                                        <Card.Header>Address</Card.Header>
                                        <Card.Body>
                                            <div className='d-flex mb-2'>
                                                <div className='w-50 mx-1'>
                                                    <Form.Group
                                                        className='mb-2'
                                                        controlId='formCity'
                                                    >
                                                        <div className='form-group required'>
                                                            <Form.Label className='control-label'>
                                                                City
                                                            </Form.Label>
                                                        </div>
                                                        <Form.Control
                                                            isInvalid={
                                                                errors?.address
                                                                    ?.city
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...register(
                                                                'address.city',
                                                            )}
                                                            type='text'
                                                            placeholder='enter city'
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {
                                                                errors?.address
                                                                    ?.city
                                                                    ?.message
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group
                                                        className='mb-2'
                                                        controlId='formSubCity'
                                                    >
                                                        <div className='form-group required'>
                                                            <Form.Label className='control-label'>
                                                                State
                                                            </Form.Label>
                                                        </div>
                                                        <Form.Control
                                                            isInvalid={
                                                                errors?.address
                                                                    ?.sub_city
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...register(
                                                                'address.sub_city',
                                                            )}
                                                            type='text'
                                                            placeholder='enter state'
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {
                                                                errors?.address
                                                                    ?.sub_city
                                                                    ?.message
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className='w-50 mx-1'>
                                                    <Form.Group
                                                        className='mb-2'
                                                        controlId='formWoreda'
                                                    >
                                                        <div className='form-group required'>
                                                            <Form.Label className='control-label'>
                                                                Street Address
                                                            </Form.Label>
                                                        </div>
                                                        <Form.Control
                                                            isInvalid={
                                                                errors?.address
                                                                    ?.woreda
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...register(
                                                                'address.woreda',
                                                            )}
                                                            type='text'
                                                            placeholder='enter street address'
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {
                                                                errors?.address
                                                                    ?.woreda
                                                                    ?.message
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group controlId='formHouseNumber'>
                                                        <div className='form-group required'>
                                                            <Form.Label className='control-label'>
                                                            Apartment/Suite Number
                                                            </Form.Label>
                                                        </div>
                                                        <Form.Control
                                                            isInvalid={
                                                                errors?.address
                                                                    ?.house_number
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...register(
                                                                'address.house_number',
                                                            )}
                                                            type='text'
                                                            placeholder='enter apartment/suite number'
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {
                                                                errors?.address
                                                                    ?.house_number
                                                                    ?.message
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'detail'}
                                    variant='primary'
                                    isLoading={creating}
                                >
                                    <IconDeviceFloppy /> Save
                                </VButton>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'new' || props.userId === user.id}
                                    className='mx-2'
                                    variant='warning'
                                    isLoading={updating}
                                >
                                    <IconEdit />
                                    Update
                                </VButton>
                                {props?.selectedItem ? (
                                    props?.selectedItem.enabled ? (
                                        <VButton
                                            onClick={onBlock}
                                            disabled={props.viewMode === 'new' || props.userId === user.id}
                                            variant='danger'
                                            isLoading={blocking}
                                            className='mr-2'
                                        >
                                            {' '}
                                            <IconTrash />
                                            Block
                                        </VButton>
                                    ) : (
                                        <VButton
                                            onClick={onActivate}
                                            disabled={props.viewMode === 'new'}
                                            variant='success'
                                            isLoading={activating}
                                            className='mr-2'
                                        >
                                            <IconTrash />
                                            Activate
                                        </VButton>
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default UserForm;
