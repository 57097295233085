import './globals.css';

import {Passenger} from 'features/model/passenger';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-modal';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {SubmitHandler, useForm} from 'react-hook-form';
import {CollectionQuery} from 'shared/model/collection.model';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconInfoSquareRounded, IconMenu2, IconSquareX} from '@tabler/icons';


import {
    useCreateOperatorRequestMutation,
    useLazyGetNearestDriversQuery,
    useLazyGetPassengerTripQuery,
    useLazyListVehicleCategoryQuery,
    useListConfigurationForTrackingQuery,
} from '../api/provider-tracking-api-service';
import useSocketIoHook from '../api/socket-io-hook';
import {schema} from '../component/form-validation';
import Destination from './destination';
import Distance from './distance';
import StartingPoint from './starting-point';
import TaxiMap from './taxi/taxi-map';
import TruckMap from './truck/truck-map';

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;

function Map() {
    const phoneRegExp =
    /^([+][1])([0-9]{10}$)/;
    const {
        formState: {errors},
    } = useForm<Passenger>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

   

    const [startingPoint, setStartingPoint] = useState<LatLngLiteral>();
    const [pickupAddress, setPickupAddress] = useState<string>();
    const [destination, setDestination] = useState<LatLngLiteral>();
    const [dropOfAddress, setDropOfAddress] = useState<string>();
    const [direction, setDirection] = useState<DirectionsResult>();
    const [showSearch, setShowSearch] = useState(true);
    const [vehicleType, setVehicleType] = useState<string>('taxi');
    const [vehicleCategory, setVehicleCategory] = useState<any>();
    const [bookingData, setBookingData] = useState<any>();
    const [estimatedCost, setEstimatedCost] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isFetchingPassengerRecord, setIsFetchingPassengerRecord] =
        useState<Boolean>(false);

    const [nearestDrivers, setNearestDrivers] = useState<Array<any>>([]);
    const [isPhoneValid, setIsPhoneValid] = useState<Boolean>(false);

    const [distance, setDistance] = useState<string>('');
    const [duration, setDuration] = useState<string>('');
    const [isReset, setIsReset] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isBooking, setIsBooking] = useState<boolean>();

    const [request, setRequest] = useState<CollectionQuery>({
        filter: [
            [
                {
                    field: 'type',
                    value: vehicleType,
                    operator: '=',
                },
            ],
            [
                {
                    field: 'is_active',
                    value: true,
                    operator: '=',
                },
            ],
        ],
    });

    const [getCategories, result] = useLazyListVehicleCategoryQuery();
    const {data: SettingConfiguration, isLoading} =
        useListConfigurationForTrackingQuery('');

    // Fetch nearest drivers info
    const [getNearestAvailbaleDrivers, availableDrives] =
        useLazyGetNearestDriversQuery();

    useEffect(() => {
        if (bookingData?.drivers?.length > 0) {
            getNearestAvailbaleDrivers(bookingData?.drivers.join(','));
        } 
        // else {
        //     setNearestDrivers([]);
        // }
    }, [bookingData]);

    useEffect(() => {
        let drivers: any[] = [];
        bookingData?.drivers?.forEach((id) => {
            availableDrives?.data?.items?.forEach((driver) => {
                if (driver.id === id) {
                    if (
                        !nearestDrivers?.some(
                            (nDriver) => nDriver?.id === driver?.id,
                        )
                    ) {
                        drivers.push(driver);
                    }
                }
            });
            setNearestDrivers(drivers);
        });
    }, [availableDrives]);
    useEffect(() => {
        getCategories(request, false);
    }, [request]);

    useEffect(() => {
        if (startingPoint && destination) {
            const service = new google.maps.DirectionsService();
            service.route(
                {
                    origin: startingPoint,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === 'OK' && result) {
                        setDirection(result);
                    }
                },
            );
        }
    }, [startingPoint, destination]);

    const onCloseSearch = () => {
        setShowSearch(!showSearch);
    };

    const { loading, sendRequest} = useSocketIoHook();

    const [addRequest, {data: orderRequestData = undefined, isSuccess}] =
        useCreateOperatorRequestMutation();

    const orderRequest = () => {
        const sendData = {
            pickup_location: bookingData?.pickupLocation,
            drop_off_location: bookingData?.droppOffLocation,
            pickup_address: pickupAddress,
            drop_off_address: dropOfAddress,
            phone_number: phoneNumber,
            distance: distance?.toString().split(' ')[0],
            duration: duration?.toString(),
            requested_from: 'dashboard',
            vehicle_category: vehicleCategory?.name,
            vehicle_type: vehicleType,
            drivers: bookingData.drivers,
        };
        addRequest(sendData);
    };

    useEffect(() => {
        if (orderRequestData && isSuccess) booking();
    }, [isSuccess, orderRequestData]);

    const [getPassengerReport, tripReport] = useLazyGetPassengerTripQuery();

    useEffect(() => {
        if (isFetchingPassengerRecord) {
            getPassengerReport(phoneNumber);

            setIsFetchingPassengerRecord(false);
        }
    }, [isFetchingPassengerRecord, getPassengerReport, phoneNumber]);

    const booking = () => {
        if (phoneNumber.length > 0 && checkPhoneNumber(phoneNumber)) {
            bookingData.passengerPhoneNumber = phoneNumber;
            bookingData.price = estimatedCost?.toString();
            bookingData.requestId = orderRequestData?.id;
            sendRequest(bookingData);
            setIsReset(true);
            setPhoneNumber('');
            setIsDirty(false);
            setIsBooking(true);
        } else {
            setIsDirty(true);
        }
    };

    const setBookingDataTest = (data) => {
        data.pickupAddress = pickupAddress;
        data.droppOffAddress = dropOfAddress;
        data.messageType = 'search';
        data.duration = duration?.toString();
        data.distance = distance?.toString();
        data.passengerName = 'passengerName';
        setBookingData(data);
    };

    const checkPhoneNumber = (phone) => {
        return phoneRegExp.test(phone);
    };

    
    const [driverId, setDriverId] = useState("")


    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',

        },
      };
      function openModal(id:string) {
         setDriverId(id)
        setIsOpen(true);
      }
      function afterOpenModal() {
       
        // subtitle.style.color = '#f00';
      }
      function closeModal() {
        setIsOpen(false);
      }
    
      const [modalIsOpen, setIsOpen] = useState(false);
    return (
        <div className='d-flex'>
            <Modal
        
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='d-flex justify-content-between align-items-center'>

        <h2>Volunteer Information</h2>
        <CloseButton onClick={closeModal} />
        {/* <button onClick={closeModal}>close</button> */}
        </div>
        
        <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Vehicle</th>
                                                <th>Phone</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nearestDrivers?.length > 0 &&
                                                nearestDrivers?.map(
                                                    (driver) => (
                                                      
                                                             driver.id === driverId && <tr key={driver}>
                                                            <td className='sm'>
                                                                {
                                                                    driver?.first_name
                                                                }{' '}
                                                                {
                                                                    driver?.last_name
                                                                }
                                                            </td>
                                                            <td className='sm'>
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .plate_number
                                                                }
                                                                ,{' '}
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .color
                                                                }{' '}
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .model
                                                                }
                                                            </td>
                                                            <td className='sm'>
                                                                {
                                                                    driver?.phone_number
                                                                }
                                                            </td>
                                                        </tr> 
                                                    
                                                    ),
                                                )}
                                        </tbody>
                                    </Table>
        </Modal>
            <div className={showSearch ? 'bg-white w-25 p-2 m-2' : 'd-none'}>
                <div
                    onClick={onCloseSearch}
                    style={{float: 'right', cursor: 'pointer'}}
                    className='mb-2'
                >
                    <IconSquareX style={{width: '30', height: '30'}} />
                </div>

                <div>
                    <Form.Group className='mb-2' controlId='formGender'>
                        <Form.Select
                            required
                            onChange={(event) => {
                                setVehicleType(event.target.value);
                                setRequest((prev) => {
                                    return {
                                        ...prev,
                                        filter: [
                                            [
                                                {
                                                    field: 'type',
                                                    value: event.target.value,
                                                    operator: '=',
                                                },
                                            ],
                                        ],
                                    };
                                });
                            }}
                        >
                            <option value='taxi'>Taxi</option>
                            {/* <option value='delivery'>Delivery</option> */}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div>
                    <Form.Group className='mb-2' controlId='formGender'>
                        <Form.Select
                            required
                            onChange={(event) => {
                                setVehicleCategory(
                                    result?.data.items[event.target.value],
                                );
                            }}
                        >
                            <option value=''>Select vehicle category</option>
                            {result?.data?.items.map((el, index) => {
                                return <option key={index} value={index}>{el.name}</option>;
                            })}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className='mb-2'>
                    <input
                        className='input-sm'
                        onChange={(event) => {
                            setIsFetchingPassengerRecord(
                                phoneRegExp.test(event.target.value),
                            );
                            setIsPhoneValid(
                                phoneRegExp.test(event.target.value),
                            );
                            setPhoneNumber(event.target.value);
                            setIsBooking(false);
                            checkPhoneNumber(event.target.value);
                            setIsDirty(true);
                        }}
                        value={phoneNumber}
                        placeholder='Youth phone number'
                        style={{width: '100%', padding: '0.2rem'}}
                        type='text'
                        pattern='[1]{1}[0-9]{10}'
                        title='Youth phone number'
                    />
                    {!phoneNumber && isDirty && (
                        <div style={{color: 'red'}}>This field is required</div>
                    )}

                    {!checkPhoneNumber(phoneNumber) &&
                        phoneNumber &&
                        isDirty && (
                            <div style={{color: 'red'}}>
                                invalid phone number
                            </div>
                        )}
                </div>

                <StartingPoint
                    startingPoint={(start: LatLngLiteral) => {
                        setIsReset(false);
                        setStartingPoint(start);
                    }}
                    startingAddress={(address: string) => {
                        setIsReset(false);
                        setPickupAddress(address);
                    }}
                    isReset={isReset}
                />

                <Destination
                    destination={(start: LatLngLiteral) => {
                        setIsReset(false);
                        setDestination(start);
                    }}
                    dropOfAddress={(address: string) => {
                        setIsReset(false);
                        setDropOfAddress(address);
                    }}
                    isReset={isReset}
                />
                {isBooking && (
                    <div className='px-2 mt-4' style={{color: 'green'}}>
                        Request has been sent successfully!
                    </div>
                )}
                {direction &&
                    vehicleCategory &&
                    pickupAddress &&
                    dropOfAddress && (
                        <div className='px-2 mt-4'>
                            <Distance
                                estimatedCost={(cost) => setEstimatedCost(cost)}
                                estimatedDuration={(dur) => setDuration(dur)}
                                estimatedDistance={(dis) => setDistance(dis)}
                                leg={direction.routes[0].legs[0]}
                                selectedVehicle={vehicleCategory}
                            />

                            <Button
                                disabled={bookingData?.drivers?.length === 0}
                                onClick={orderRequest}
                                className='bottom-0 '
                            >
                                {loading && (
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                        className='mr-2 overflow-hidden'
                                    />
                                )}
                                Send Request
                            </Button>

                            {nearestDrivers?.length > 0 && (
                                <div className='mt-3'>
                                    <h5>Nearby Volunteers</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Car</th>
                                                <th>Phone</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nearestDrivers?.length > 0 &&
                                                nearestDrivers?.map(
                                                    (driver, i) => (
                                                        <tr key={i}>
                                                            <td className='sm'>
                                                                {
                                                                    driver?.first_name
                                                                }{' '}
                                                                {
                                                                    driver?.last_name
                                                                }
                                                            </td>
                                                            <td className='sm'>
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .plate_number
                                                                }
                                                                ,{' '}
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .color
                                                                }{' '}
                                                                {
                                                                    driver
                                                                        ?.vehicle
                                                                        .model
                                                                }
                                                            </td>
                                                            <td className='sm'>
                                                                {
                                                                    driver?.phone_number
                                                                }
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                            {nearestDrivers?.length === 0 && (
                                <Alert variant={'info'} className='mt-4'>
                                    <div className='d-flex flex-row align-content-center justify-content-center'>
                                        {availableDrives?.isLoading ? (
                                            <Spinner
                                                animation='border'
                                                role='status'
                                            >
                                                <span className='visually-hidden'>
                                                    Loading...
                                                </span>
                                            </Spinner>
                                        ) : (
                                            <>
                                                <div>
                                                    <IconInfoSquareRounded />
                                                </div>
                                                <div className='px-2 fs-6'>
                                                    {' '}
                                                    There is no available
                                                    volunteer with the given
                                                    radius.
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Alert>
                            )}
                        </div>
                    )}
            </div>

            <div className={showSearch ? 'w-75' : 'w-100'}>
                {!showSearch && (
                    <div
                        style={{
                            float: 'right',
                            marginTop: -15,
                            marginBottom: 2,
                        }}
                    >
                        <Button size='sm' onClick={onCloseSearch}>
                            <IconMenu2 />
                        </Button>
                    </div>
                )}

                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                    {isFetchingPassengerRecord && (
                        <div>
                            <p>Fetching passenger record...</p>
                            <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                                className='mr-2 overflow-hidden'
                            />
                        </div>
                    )}

                    {tripReport?.data &&
                        tripReport?.data.length === 0 &&
                        isPhoneValid && <p>No youth record found</p>}

                    {tripReport?.data &&
                        tripReport?.data.length > 0 &&
                        isPhoneValid && (
                            <div>
                                <h5>Previous youth record</h5>
                                <Table size='sm' striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Pickup address</th>
                                            <th>Drop off address</th>
                                            <th>status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tripReport?.data.map((report, i) => (
                                            <tr key={i}>
                                                <td>{report.pickup_address}</td>
                                                <td>
                                                    {report.drop_off_address}
                                                </td>
                                                <td>{report.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>{' '}
                            </div>
                        )}

                    {vehicleType === 'taxi' && vehicleCategory ? (
                        <TaxiMap
                            openModal={openModal}
                            sendData={(data) => setBookingDataTest(data)}
                            start={startingPoint}
                            end={destination}
                            vehicleCategory={vehicleCategory.name}
                            SettingConfiguration={SettingConfiguration}
                            isReset={isReset}
                        />
                    ) : (
                        <TruckMap
                            sendData={(data) => setBookingDataTest(data)}
                            start={startingPoint}
                            end={destination}
                            SettingConfiguration={SettingConfiguration}
                            isReset={isReset}
                        />
                    )}
                </div>

                {/* render user info here */}
            </div>
        </div>
    );
}

export default Map;

const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
};
const closeOptions = {
    ...defaultOptions,
    zIndex: 3,
    fillOpacity: 0.05,
    strokeColor: '#8BC34A',
    fillColor: '#8BC34A',
};
const middleOptions = {
    ...defaultOptions,
    zIndex: 2,
    fillOpacity: 0.05,
    strokeColor: '#FBC02D',
    fillColor: '#FBC02D',
};
const farOptions = {
    ...defaultOptions,
    zIndex: 1,
    fillOpacity: 0.05,
    strokeColor: '#FF5252',
    fillColor: '#FF5252',
};

const generateHouses = (position: LatLngLiteral) => {
    const _houses: Array<LatLngLiteral> = [];
    for (let i = 0; i < 100; i++) {
        const direction = Math.random() < 0.5 ? -2 : 2;
        _houses.push({
            lat: position.lat + Math.random() / direction,
            lng: position.lng + Math.random() / direction,
        });
    }
    return _houses;
};
