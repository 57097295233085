import { googleMapApiKey } from 'config/constants';
import { GoogleApiWrapper, Map, Marker, Polyline  } from 'google-maps-react';
import React, { Component } from 'react';

interface MapProps {
    sender_latitude: any;
    sender_longitude: any;
    reciever_latitude: any;
    reciever_longitude: any;
    google: any;
}



class GoogleMapContainer extends Component<MapProps> {

    render() {
        var points = [
            { lat: this.props.sender_latitude, lng: this.props.sender_longitude },
            { lat: this.props.reciever_latitude, lng: this.props.reciever_longitude },

        ]
        // var bounds = new this.props.google.maps.LatLngBounds();
        // for (var i = 0; i < points.length; i++) {
        //     bounds.extend(points[i]);
        // }

        return (
            <Map
                // bounds={bounds}
                gestureHandling="none"
                initialCenter={{
                    lat: this.props.sender_latitude,
                    lng: this.props.sender_longitude,
                }}
                google={this.props.google}
                zoom={15}
                
            >
                    <Polyline 

          path={points}
          strokeColor="black"
          strokeOpacity={0.8}
          strokeWeight={4} />
                <Marker
                    title='Sender Location'
                    position={{
                        lat: this.props.sender_latitude,
                        lng: this.props.sender_longitude,
                    }}
                />
                <Marker
                title='Reciever Location'
                    position={{

                        lat: this.props.reciever_latitude,
                        lng: this.props.reciever_longitude,
                    }}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: googleMapApiKey,
})(GoogleMapContainer);
