import "../../assets/scss/navigation.scss";

import { applicationSetting } from "config/app-setting";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { userService } from "services/auth/user-services";

import logo from "../../assets/img/logo.png";

function Navigation() {
  // const role = userService.getCurrentUserRoles
  const userRole = userService.currentRole;
  // const userHasRequiredRole = user && roles?.includes(userRole) ? true : false
  const [visibleMenus, setVisibleMenus] = useState<any>([]);

  let foundRole = applicationSetting.role.find((r) => r.name === userRole);

  useEffect(() => {
    setVisibleMenus(foundRole?.menu);
  }, [foundRole]);

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const show_menu = (event?: React.MouseEvent<HTMLElement>) => {
    event?.currentTarget.parentElement?.parentElement?.classList.toggle(
      "show_menu"
    );
  };

  const getClassName = (menu): string => {
    var className = "";
    switch (menu) {
      case "Dashboard":
        className = "bx bx-grid-alt";
        break;
      case "Registration":
        className = "bx bx-message-square";
        break;
      case "Users":
        className = "bx bx-group";
        break;
      case "Vehicle":
        className = "bx bx-car";
        break;
      case "Volunteer":
        className = "bx bx-group";
        break;
      case "Youth":
        className = "bx bx-group";
        break;
      case "Requests":
        className = "bx bx-message-square";
        break;
      case "Dispatcher":
        className = "bx bx-map-pin";
        break;

      case "Push Notification":
        className = "bx bx-bell";
        break;
      case "Settings":
        className = "bx bx-cog";
        break;
      case "Feedback":
        className = "bx bx-message";
        break;
      case "Review":
        className = "bx bx-message";
        break;
      // case 'Bank Credit':
      //     className = 'bx bx-home';
      //     break;
      case "Reports":
        className = "bx bx-home";
        break;
      case "Award":
        className = "bx bx-home";
        break;
      case "Emergency Report":
        className = "bx bx-home";
        break;
      // case 'Delivery Requests':
      //     className = 'bx bx-car';
      //     break;
    }
    return className;
  };
  return (
    <div className="sidebar" id="sidebar" ref={sidebarRef}>
      <div className="logo-details">
        <div>
          <img id="logo-icon" src={logo} alt="Drumm  Farm logo" />
        </div>
        <div className="logo_name">Drumm Farm</div>
      </div>
      <div className="nav_links">
        {visibleMenus?.map((menu: any, index) => {
          if (menu.name === "Registration") {
            return (
              <li key={index}>
                <div className="iocn_link">
                  <NavLink to="">
                    <i className="bx bx-message-square"></i>
                    <div className="link_name">{menu.name}</div>
                  </NavLink>
                  <i
                    className="bx bxs-chevron-down arrow"
                    onClick={show_menu}
                  ></i>
                </div>
                <ul className="sub_menu">
                  <li>
                    <NavLink className="link_name  nav-item" to="">
                      Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/owner" className={"nav-item"}>
                      Owner
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vehicle" className={"nav-item"}>
                      {/* <i className="bx bx-taxi"></i> */}
                      Vehicle
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/driver" className={"nav-item"}>
                      {/* <i className="bx bx-user"></i> */}
                      Volunteers
                    </NavLink>
                  </li>
                </ul>
              </li>
            );
          }
          if (menu.name === "Reports") {
            return (
              <li key={index}>
                <div className="iocn_link">
                  <NavLink to="">
                    <i className="bx bx-message-square"></i>
                    <div className="link_name">Reports</div>
                  </NavLink>
                  <i
                    className="bx bxs-chevron-down arrow"
                    onClick={show_menu}
                  ></i>
                </div>
                <ul className="sub_menu">
                  <li>
                    <NavLink className="link_name" to="">
                      Reports
                    </NavLink>
                  </li>
                  {/* <li>
                                        <NavLink
                                            className={'nav-item'}
                                            to='/reports/company-earing'
                                        >
                                            Company Earning
                                        </NavLink>
                                    </li> */}

                  <li>
                    <NavLink className={"nav-item"} to="/reports/request">
                      Requests
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={"nav-item"}
                      to="/reports/registered-drivers"
                    >
                      {" "}
                      Registered Volunteers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={"nav-item"}
                      to="/reports/registered-customers"
                    >
                      Registered Youths
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={"nav-item"}
                      to="/reports/cancellation-reasons"
                    >
                      Cancellation Reasons
                    </NavLink>
                  </li>
                </ul>
              </li>
            );
          }
          if (menu.name === "Settings") {
            return (
              <li key={index}>
                <div className="iocn_link">
                  <NavLink to="">
                    <i className="bx bx-cog"></i>
                    <span className="link_name">Settings</span>
                  </NavLink>
                  <i
                    className="bx bxs-chevron-down arrow"
                    onClick={show_menu}
                  ></i>
                </div>
                <ul className="sub_menu">
                  <li>
                    <NavLink
                      className={"nav-item"}
                      to="/settings/configuration"
                    >
                      Configuration
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={"nav-item"}
                      to="/settings/vehicle-category"
                    >
                      Vehicle Category
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className={"nav-item"} to="/settings/addresses">
                      Addresses
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="link_name nav-item" to="">
                      Settings
                    </NavLink>
                  </li>
                  {/* <li>
                      <NavLink to="/settings/promo_codes">Promo Codes</NavLink>
                    </li> */}
                </ul>
              </li>
            );
          } else {
            return (
              <li key={menu.name}>
                <NavLink to={menu.url} className={"nav-item"}>
                  <i className={getClassName(menu.name)}></i>
                  <span className="link_name">{menu.name}</span>
                </NavLink>
                <ul className="sub_menu blank">
                  <li>
                    <NavLink className="link_name nav-item" to={menu.url}>
                      {menu.name}
                    </NavLink>
                  </li>
                </ul>
              </li>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Navigation;
