import '@reach/combobox/styles.css';

import React, {useEffect, useState} from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';

import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxPopover,
} from '@reach/combobox';
import { useLazyGetAddressesQuery } from 'features/settings/api/address-api-service';
import { CollectionQuery } from 'shared/model/collection.model';

type PlacesProps = {
    startingPoint: (position: any) => void;
    startingAddress: (pickup_address: any) => void;
    isReset: boolean;
};

function StartingPoint({startingPoint, startingAddress, isReset}: PlacesProps) {
    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const [request, setRequest] = useState<CollectionQuery>({
        
        filter: [
            [
                {
                    field: 'active',
                    value: true,
                    operator: '=',
                },
            ],
        ],
      
    });

    const handleSelect = async (val: string) => {
        setValue(val, false);
        clearSuggestions();
        const results = await getGeocode({address: val});
        const {lat, lng} = await getLatLng(results[0]);
        startingPoint({lat, lng});
        startingAddress(val);
    };

    const [getAddresses, result] = useLazyGetAddressesQuery();
    const [items, setItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
  
    useEffect(() => {
        getAddresses(request);
    }, [ getAddresses])

    useEffect(() => {
        setItems(result?.data?.items);
        console.log("from starting", result?.data?.items);
    }, [result])

    useEffect(() => {
        if (isReset) {
            setValue('');
            startingAddress(undefined);
            startingPoint({lat: undefined, lng: undefined});
        }
    }, [isReset]);

    return (
        <Combobox onSelect={handleSelect} className='mb-2'>
            <ComboboxInput
                value={value}
                onChange={
                    (e) => {setValue(e.target.value);
                   const newArray = items.filter((item: any) => item.name.toLowerCase().includes(e.target.value.toLocaleLowerCase().trim()))
                   setFilteredItems(newArray);
          
                  }
                  }
                disabled={!ready}
                className='combobox-input input-sm'
                placeholder='Select pickup address'
            />
            <ComboboxPopover>
                <ComboboxList>
                    {/* {status === 'OK' &&
                        data.map(({place_id, description}) => (
                            <ComboboxOption
                                key={place_id}
                                value={description}
                            />
                        ))} */}

{ items && items.length > 0 && filteredItems.map(({name}, index) => (
              <ComboboxOption key={index} value={name}/>
            ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
}

export default StartingPoint;
