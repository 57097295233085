import * as yup from 'yup';

const phoneRegExp =
    /^([+][1])([0-9]{10}$)/;

export const schema = yup
    .object({
        id: yup.string(),
        first_name: yup.string().required('First name is required'),
        last_name: yup.string().required('Last name is required'),
        phone_number: yup
            .string()
            .required('Phone number is required')
            .matches(phoneRegExp, 'invalid phone number'),
        email: yup.string().email(),
        address: yup
            .object()
            .shape({
                city: yup.string().required('City is required'),
                sub_city: yup.string().required('State is required'),
                house_number: yup.string().required('Apartment/suite number is required'),
                woreda: yup.string().required('street address is required'),
            })
            .required(),
        // tin: yup.string().required('TIN is required'),
        gender: yup.string().required('Gender is required'),
    })
    .required();
