import React, {useEffect, useState} from 'react';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListAcceptedRequestQuery} from '../api/accepted_requests-api-service';

function RequestList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['passenger', 'driver'],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['vehicle_category', 'vehicle_type', 'phone_number', "passenger_info.phone_number"],
    });

    const [getData, result] = useLazyListAcceptedRequestQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // alert('searching use effect')
        getData(request, false);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: '/accepted-request',
        title: ' Requests List',
        detailUrl: '',
        showNewButton: false,
        visibleColumn: [
            {key: 'pickup_address', name: 'Pickup Address'},
            {key: 'drop_off_address', name: 'Drop Of Address'},
            {
                key: ['passenger_info', 'name'],
                name: 'Youth',
                postFix: {key: ['passenger_info', 'phone_number']},
            },
            {
                key: ['fullName'],
                name: 'Volunteer',
                postFix: {key: ['driver', 'phone_number']},
            },
            {key: 'created_at', name: 'Date', isDate: true},
            {key: 'status', name: 'Status'},
        ],
        filter: [
            [
                {
                    field: 'status',
                    value: 'Accepted',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Accepted',
                },
                {
                    field: 'status',
                    value: 'Completed',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Completed',
                },
                {
                    field: 'status',
                    value: 'Cancelled',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Cancelled',
                },
                {
                    field: 'status',
                    value: 'Started',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Started',
                },
                {
                    field: 'status',
                    value: 'Pending',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Pending',
                },
                {
                    field: 'status',
                    value: 'Time Out',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Timeout',
                },
                {
                    field: 'status',
                    value: 'Scheduled',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Scheduled',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
       
        setRequest((prev) => {
            return {...prev, search: req, skip: 0, top: 10};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req, top: 10, skip: 0};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default RequestList;
