import React, {useEffect, useState} from 'react';
import {CollectionQuery} from 'shared/model/collection.model';

import EntityList from '../../../shared/entity/entity-list';
import {EntityConfiguration} from '../../../shared/entity/models';
import {useLazyListPassangerQuery} from '../api/passanger-api-service';

function PassangerList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['name', 'phone_number'],
    });

    const [getData, result] = useLazyListPassangerQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(result?.isLoading);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: 'passanger',
        title: 'Youths List',
        detailUrl: 'passanger-detail',
        showNewButton: false,
        visibleColumn: [
            {key: 'name', name: 'Name'},
            // { key: 'email', name: 'Email' },
            {key: 'phone_number', name: 'Phone Number'},
            {key: 'emergency_contact', name: 'Emergency Contact'},
            {key: 'created_at', name: 'Registered At', isDate: true},
            {key: 'enabled', name: 'Status', isBoolean: true},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };
    return (
        <div>
            <EntityList
                itemsLoading={loading}
                pagination={onPagination}
                search={onSearch}
                items={items}
                config={config}
                total={total}
                filter={onFilter}
            />
        </div>
    );
}

export default PassangerList;
