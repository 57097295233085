import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import { useLazyGetAddressesQuery } from 'features/settings/api/address-api-service'
import { CollectionQuery } from 'shared/model/collection.model'

type PlacesProps = {
  destination: (position: any) => void
  dropOfAddress: (address: any) => void
  isReset: boolean
}

function Destination({ destination, dropOfAddress, isReset }: PlacesProps) {
  var options = {
    types: ['(cities)'],
    componentRestrictions: { country: 'us' },
  }

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const [request, setRequest] = useState<CollectionQuery>({
        
    filter: [
        [
            {
                field: 'active',
                value: true,
                operator: '=',
            },
        ],
    ],
  
});


  const handleSelect = async (val: string) => {
    setValue(val, false)
    clearSuggestions()

    const results = await getGeocode({
      address: val,
      // bounds: { north: 9.145, east: 40.4897, south: 0, west: 0 },
      // componentRestrictions: { country: 'Ethiopia', postalCode: '1000' },
    })
    const { lat, lng } = await getLatLng(results[0])
    destination({ lat, lng })
    dropOfAddress(val)
  }
  useEffect(() => {
    if (isReset) {
      setValue('')
      dropOfAddress(undefined)
      destination({ lat: undefined, lng: undefined })
    }
  }, [isReset])

  const [getAddresses, result] = useLazyGetAddressesQuery();
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  useEffect(() => {
      getAddresses(request);
  }, [ getAddresses])

  useEffect(() => {
      setItems(result?.data?.items);
      setFilteredItems(result?.data?.items);
      console.log("from destination", result?.data?.items);
  }, [result])
  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={
          (e) => {setValue(e.target.value);
         const newArray = items.filter((item: any) => item.name.toLowerCase().includes(e.target.value.toLocaleLowerCase().trim()))
         setFilteredItems(newArray);

        }
        }
        disabled={!ready}
        className="combobox-input"
        placeholder="choose destination"
      />
      <ComboboxPopover>
        <ComboboxList>
          {/* {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))} */}

            { items && items.length > 0 && filteredItems.map(({name}, index) => (
              <ComboboxOption key={index} value={name}/>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default Destination
