import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {Outlet, useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {CollectionQueryTwo} from 'shared/model/collection.model-two';

import {useLazyRegisteredPassengerQuery} from '../api/report-api-services';

interface requestModel {
    startDate: string;
    endDate: string;
    format: string;
}
function RegisteredCustomers() {
    const [isCollapsed, setIsCollapsed] = useState(true);

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const [getData, result] = useLazyRegisteredPassengerQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<any>(formatDate);
    const [endDate, setEndDate] = useState<any>(formatDate);
    const [type, setType] = useState<string>('d');

    const [request, setRequest] = useState<CollectionQuery>({
        top: 10,
        skip: 0,
    });

    useEffect(() => {
        if (startDate && endDate) {
            setRequest({
                filter: [
                    [
                        {
                            field: 'createdAt',
                            value: [startDate, endDate],
                            operator: 'between',
                            type: 'date',
                        },
                    ],
                ],
                format: type,
            });
        }
    }, [type, startDate, endDate]);

    useEffect(() => {
        getData(request);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/settings/vehicle-category',
        title: 'Registered Youths',
        detailUrl: 'detail',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'registered_date', name: 'Registered Date'},
            {key: 'count', name: 'Total'},
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };
    return (
        <Card className='mt-2'>
            <Card.Body>
                <div className='d-flex justify-content-center items-center my-2'>
                    <div className='mx-4'>
                        <label>Start Date</label>
                        <input
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                            value={startDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                        />
                    </div>

                    <div className='mx-4'>
                        <label>End Date</label>
                        <input
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                            value={endDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                            min={startDate}
                        />
                    </div>

                    <div>
                        <label>Type</label>
                        <select
                            required
                            onChange={(event) => {
                                setType(event.target.value);
                                setRequest((prev) => {
                                    return {
                                        ...prev,
                                        format: event.target.value,
                                    };
                                });
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value='d'>Daily</option>
                            <option value='m'>Monthly</option>
                            <option value='y'>Yearly</option>
                        </select>
                    </div>
                </div>
                <EntityList
                    itemsLoading={loading}
                    items={items || []}
                    total={total || 0}
                    config={config}
                    search={onSearch}
                    pagination={onPagination}
                    filter={onFilter}
                />
            </Card.Body>
        </Card>
    );
}
export default RegisteredCustomers;
