import { CollectionQuery, DetailQuery } from './collection.model';



export const collectionQueryBuilder = (
  request: CollectionQuery,
) => {
  const params = new URLSearchParams();
  if (request?.skip !== undefined) {
    const skip ='skip';
    params?.set(skip, request.skip.toString());
  }

  if (request?.top !== undefined) {
    const top ='top';
    params?.set(top, request.top.toString());
  }

  if (request?.search !== undefined && request?.search !== '') {
    params?.set('search', request.search.toString());
  }

  if (request?.groupBy !== undefined && request?.groupBy !== '') {
    params?.set('groupBy', request.groupBy.toString());
  }

  if (request?.searchFrom && request?.searchFrom?.length > 0) {
 
      params?.append(`searchFrom`, request.searchFrom.toString());
   
  }

  if (request?.orderBy && request?.orderBy?.length > 0) {
    request?.orderBy?.forEach((orderBy: any, index) => {
      Object.keys(orderBy).forEach((key) => {
        params?.append(`orderBy[${index}][${key}]`, encodeURI(orderBy[key]));
      });
    });
  }
  // if (request?.groupBy && request?.groupBy?.length > 0) {
  //   request?.groupBy?.forEach((groupBy, index) => {
  //     params?.append(`groupBy[${index}]`, encodeURI(groupBy));
  //   });
  // }
  if (request?.filter && request?.filter?.length > 0) {
      request?.filter?.forEach((filterAnd, index) => {
        filterAnd.forEach((filterOr: any, orIndex) => {
          Object.keys(filterOr).forEach((key) => {
            if (filterOr[key]) {
              params?.append(
                `filter[${index}][${orIndex}][${key}]`,
                filterOr[key]
              );
            }
          
          });
        });
      });
    
  }
  if (request?.select && request?.search) {
    request?.select.forEach((select, index) => {
      params.append(`select[${index}]`, select);
    });
  }
  if (request?.includes && request.includes?.length > 0) {
    request.includes.forEach((include, index) => {
      params.append(`includes[${index}]`, include);
    });
  }
  if (request?.count !== undefined) {
    params?.set('count', request?.count.toString());
  }

  if (request?.id !== undefined && request?.id !== '') {
    params?.set('id', request.id.toString());
  }

  if (request?.format !== undefined && request?.format !== '') {
    params?.set('format', request.format.toString());
  }

  return params;
};

export const findById = (id: string, locale: any) => {
  const request: DetailQuery = {
    filter: [
      [
        {
          field: 'id',
          value: id,
          operator: '=',
        },
      ],
    ],
  };
  request.locale = locale;

  return collectionQueryBuilder(request);
};
