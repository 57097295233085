import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {bankEndpoint} from './bank-endpoint';

const BankApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listPublicProviderForCredit: builder.query({
                query: (query) => ({
                    url: `${bankEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getPublicProviderByIdForCredit: builder.query({
                query: (id: string) => ({
                    url: `${bankEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            addCreditFromBank: builder.mutation({
                query: (credit: any) => ({
                    url: `${bankEndpoint.addCredit}`,
                    method: 'post',
                    data: credit,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Credit added Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='not added Something went wrong'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            getBankCredit: builder.query({
                query: (id: string) => ({
                    url: `${bankEndpoint.getMyCredit}?driver_id=${id}`,
                    method: 'get',
                }),
            }),
            listBankCreditHistory: builder.query({
                query: (query: any) => ({
                    url: `${bankEndpoint.listCreditHistory}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
        };
    },
});

export const {
    useLazyListPublicProviderForCreditQuery,
    useAddCreditFromBankMutation,
    useGetPublicProviderByIdForCreditQuery,
    useLazyListBankCreditHistoryQuery,
    useGetBankCreditQuery,
} = BankApiService;
