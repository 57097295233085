import React, {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {IconPremiumRights, IconX} from '@tabler/icons';

import {
    useLazyGetAllDriversTotalCreditQuery,
    useLazyListPublicProviderQuery,
} from '../api/public-provider-services';

function PublicProviderList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['updated_by', 'created_by', 'credit'],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['first_name', 'last_name', 'phone_number'],
    });

    const [getData, result] = useLazyListPublicProviderQuery();
    const [getAllDriversTotalCredit, totalCredit] =
        useLazyGetAllDriversTotalCreditQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [credit, setCredit] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        getAllDriversTotalCredit(null);
    }, []);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: 'public-driver',
        title: 'Volunteers List',
        detailUrl: 'public-driver-detail',
        showNewButton: false,
        visibleColumn: [
            {key: 'first_name', name: 'First Name'},
            {key: 'last_name', name: 'Last Name'},
            {key: 'phone_number', name: 'Phone Number'},
            // {key: ['credit', 'balance'], name: 'Credit'},
            {key: ['avg_rate', 'score'], name: 'Rating'},
            {
                key: ['updated_by', 'first_name'],
                name: 'Registered By',
                postFix: {key: ['updated_by', 'last_name']},
            },
            {key: 'created_at', name: 'Registered At', isDate: true},
            {key: 'enabled', name: 'Status', isBoolean: true},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <div className='w-full'>
            {/* {credit && totalCredit?.isSuccess && totalCredit?.data && (
                <Alert key={'info'} variant={'info'} className='h-25'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center gap-2'>
                            <IconPremiumRights size={80} />
                            <div>
                                <div className='fs-4'>
                                    All drivers total credit
                                </div>
                                <div className='fs-5'>
                                    {new Intl.NumberFormat().format(
                                        totalCredit?.data?.total,
                                    )}
                                    ETB
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-start'>
                            <IconX
                                size={14}
                                style={{cursor: 'pointer'}}
                                onClick={() => setCredit(false)}
                            />
                        </div>
                    </div>
                </Alert>
            )} */}
            <EntityList
                itemsLoading={loading}
                items={items}
                total={total}
                config={config}
                search={onSearch}
                pagination={onPagination}
                filter={onFilter}
            />
        </div>
    );
}

export default PublicProviderList;
