import { IconPlus, IconSearch, IconTrash } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Table,
  DropdownButton,
  Dropdown,
  FormControl,
  InputGroup,
  
  Form,
  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import {
  EntityConfiguration,
  PDF,
  PDFOrientationOption,
  PDFSizeOption,
  PDFUnitOption,
} from './models'
import './entity-list.scss'
import ReactPaginate from 'react-paginate'
import * as _ from 'lodash'
import EmptyState from 'shared/component/empty-state'
import SpinnerForPages from 'shared/component/spinner-for-pages'
interface EntityListProps {
  items: any[]
  total: number
  itemsLoading?: boolean
  config: EntityConfiguration
  search: any
  reportTitle?: string
  pdfConfig?: PDF
  pagination: any
  filter: any
  delete?: (item) => void
}
function EntityList(props: EntityListProps) {
  const [items, setItems] = useState<any[]>(props.items)
  const [total, setTotal] = useState<number>(props.total)
  const [pageSize, setPageSize] = useState<number>(10)
  const [config, setConfig] = useState<EntityConfiguration>(props.config)
  const [pdfConfig, setPdfConfig] = useState<PDF | undefined>(props.pdfConfig)
  const [dropdownTitle, setDropdawnTitle] = useState<string>('10 / page')
  const [isTableExpand, setIsTableExpand] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [expandedRow, setExpandedRow] = useState<any>(undefined)
  const [pageNumber, setPageNumber] = useState(0)

  const childeView = (item: any, keys: string[]) => {
    if (keys.length && item) {
      keys.forEach((key: any) => {
        if (item[key] !== null && item[key] !== undefined) {
          item = item[key]
        } else {
          item = ''
        }
      })
    }

    return item
  }

  const postFix = (item: any, keys: any) => {
    if (keys.length && item) {
      keys.forEach((key: any) => {
        if (item[key] !== null && item[key] !== undefined) {
          item = item[key]
        } else {
          item = ''
        }
      })
    }

    return item
  }

  useEffect(() => {
    setItems(props.items)
    setLoading(props.itemsLoading ? props.itemsLoading : false)
    setConfig(props.config)
  }, [props.items, props.config, props.itemsLoading])

  useEffect(() => {
    setTotal(props.total)
    setPdfConfig(props.pdfConfig)
  }, [props.total, props.pdfConfig])

  const expandTable = (data) => {
    if (!expandedRow || expandedRow?.id === data.id) {
      setIsTableExpand(!isTableExpand)
      setExpandedRow(data)
    } else {
      setIsTableExpand(true)
      setExpandedRow(data)
    }
  }
  const exportPDF = () => {
    const unit = pdfConfig?.unit ? pdfConfig?.unit : PDFUnitOption.PT
    const size = pdfConfig?.size ? pdfConfig.size : PDFSizeOption.A4
    const orientation = pdfConfig?.orientation
      ? pdfConfig.orientation
      : PDFOrientationOption.Landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = `${
      props?.reportTitle ? props?.reportTitle : config.title
    }  Report`
    const headers: any[] = []

    var printerData: any[] = []
    if (pdfConfig?.visibleColumn?.length) {
      var head: any[] = []
      pdfConfig?.visibleColumn?.map((col) => 
        head.push(col.name)
      )
      headers.push(head)

      items.map((elt: any) => {
        let result: any[] = []
        pdfConfig?.visibleColumn?.map((col) => {
          result = [
            ...result,
            elt[!Array.isArray(col.key) ? col.key : col.key[0]],
          ]
        })

        printerData.push({ ...result })
      })
    } else {
      var key = Object.keys(items[0])
      var changedKey: any[] = []
      key.map((element) => {
        changedKey.push(_.startCase(element))
      })
      headers.push(changedKey)

      items.map((element) => {
        let result: any[] = []
        Object.keys(items[0]).map((key) => 
          result.push(element[key])
        )
        printerData.push(result)
      })
    }

    let content = {
      startY: 50,
      head: headers,
      body: printerData,
    }

    doc.text(title, marginLeft, 40)
    autoTable(doc, content)
    doc.save(`${pdfConfig?.fileName ? pdfConfig?.fileName : 'report'}.pdf`)
  }

  const onPageIndexChange = (page: any) => {
    setLoading(true)
    setPageNumber(page?.selected)
    const request = {
      skip: page?.selected * pageSize,
      top: pageSize,
    }
    props.pagination(request)
  }
  const onPageSizeChange = (page: number) => {
    const request = {
      skip: 0,
      top: page,
    }
    setPageSize(page)
    props.pagination(request)
  }
  const [filterParam, setFilterParam] = useState<any[]>([])
  let filterOptions

  if (props?.config?.filter?.length) {
    filterOptions = props.config.filter.map((option, index) => {
      return (
        <div key={index} style={{ width: '250px' }}>
          <div className="border-b m-2 fw-bold fs-6">
            Filtered By {option[0].fieldName}
          </div>
          <div className="px-2" key={option[0].field}>
            {option.map((opt, index) => {
              return (
                <Form.Check
                  key={index}
                  id={opt.value}
                  label={opt.name}
                  onChange={(event) => onFilter(event, opt)}
                  className="my-2"
                />
              )
            })}
          </div>
        </div>
      )
    })
  }
  const onFilter = (event: any, selectedField: any) => {
    // Adds and removes filter params into the query
    event.target.checked
      ? setFilterParam((prev) => {
          return [
            ...prev,
            {
              field: selectedField.field,
              operator: selectedField.operator,
              value: selectedField.value,
            },
          ]
        })
      : setFilterParam((prev) => {
          return prev.filter((a) => a.value !== selectedField.value)
        })
  }

  useEffect(() => {
    // groups the filter query by their filter key

    const filterMap: { [key: string]: any[] } = {}
    filterParam.forEach((item) => {
      filterMap[item.field] = filterParam.filter(
        (query) => query?.field === item.field,
      )
    })
    constructTheFilterQueary(filterMap)
  }, [filterParam])

  const constructTheFilterQueary = (filterMap) => {
    // constructs the filter query into array form the grouped object
    let filterQuery: any[] = []
    Object.keys(filterMap).forEach((key) => {
      filterQuery = [...filterQuery, filterMap[key]]
    })
    emitFilterQuery(filterQuery)
  }
  const emitFilterQuery = (filterQuery) => {
    props.filter(filterQuery)
  }
  const dateFormater = (date) => {
    if (date) {
      const data = new Date(date)
      // Results below assume UTC timezone - your results may vary
      const result = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      }).format(data)
      // Specify default date formatting for language (locale)
      return result
    }
  }
  return (
    <>
      {loading ? (
        <SpinnerForPages />
      ) : (
        <Card style={{overflow: "auto"}}>
          <Card.Header>
            <div  className="d-flex justify-content-between">
              <div>{config?.title}</div>
              <div className={config?.showNewButton === false ? 'd-none' : ''}>
                <Button
                  variant="outline-success"
                  size="sm"
                  className="background_primary text-white border"
                >
                  <Link
                    className="text-decoration-none text-white"
                    to={`${config.rootUrl}/new`}
                  >
                    {' '}
                    <IconPlus /> Add
                  </Link>
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="container-fluid h-100">
            <>
              <div className="d-flex justify-content-between mb-2">
                <div>
                  <DropdownButton
                    size="sm"
                    variant="outline-success"
                    
                    className="background_primary text-white border custom-dropdown-button"
                    title="Action"
                    id="input-group-dropdown-1"
                    disabled={!props?.items?.length}
                  >
                    <Dropdown.Item className="mb-2" onClick={exportPDF}>
                      Export PDF
                    </Dropdown.Item>
                    <CSVLink
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                        padding: '16px',
                      }}
                      data= {items ? items : [] }
                    >
                      Export CSV
                    </CSVLink>
                  </DropdownButton>
                </div>
                <div className="d-flex  w-50 p-1">
                  <div
                    className={!config.filter?.length ? 'w-100' : 'w-75 mx-2'}
                  >
                    <InputGroup>
                      <FormControl
                        onKeyUp={(event: any) => {
                          const value = event.target.value;
                          const sanitizedValue = value.replace(/\+/g, '');
                          props.search(sanitizedValue);
                        }
                        }
                        placeholder="search here"
                        aria-placeholder="basic-search"
                      />
                      <InputGroup.Text id="basic-search">
                        <IconSearch />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className={!config.filter?.length ? 'd-none' : ''}>
                    <DropdownButton
                      variant="outline-secondary"
                      title="Filter"
                      id="input-group-dropdown-2"
                    >
                      {filterOptions}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <Table size="sm" striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    {config.isTableCallapsedable === true && <th></th>}
                    {config?.visibleColumn.map((col, index) => {
                      return <th key={index} >{col?.name}</th>
                    })}
                    {props?.delete && <th style={{ width: '3%' }}>Actions</th>}
                  </tr>
                </thead>

                {total === 0 ? (
                  <tbody >
                    <tr
                      style={{
                        height: '100px',
                        textAlign: 'center',
                        marginTop: '200px',
                      }}
                    >
                      <td colSpan={12}>
                        <EmptyState />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {items?.map((data, i) => {
                      return (
                        <React.Fragment key={data?.id}>
                          <tr>
                            <td>{i + 1}</td>
                            {config.isTableCallapsedable === true && (
                              <td key={data?.id}>
                                <div
                                  className="expand"
                                  onClick={() => expandTable(data)}
                                >
                                  {isTableExpand && expandedRow?.id === data?.id
                                    ? '-'
                                    : '+'}
                                </div>
                              </td>
                            )}
                            {config.visibleColumn.map((col, index) => {
                              if (config.hasDetail === false) {
                                return (
                                  <td key={index}>
                                    {!Array.isArray(col.key)
                                      ? col.isDate
                                        ? dateFormater(data[col.key])
                                        : typeof data[col.key] === 'boolean'
                                        ? data[col.key]
                                          ? 'Active'
                                          : 'Inactive'
                                        : data[col.key]
                                      : col.postFix
                                      ? `${childeView(data, col.key)} ` +
                                        `   ${postFix(data, col.postFix?.key)}`
                                      : childeView(data, col.key)}
                                  </td>
                                )
                              } else {
                                return (
                                  <td
                                  key={index}
                                    style={{
                                      fontWeight:
                                        data?.is_viewed === false ? 'bold' : '',
                                    }}
                                  >
                                    <Link
                                      to={
                                        
                                        config?.detailUrl
                                          ? `${config.detailUrl}/${data.id ? data.id : data._id}`
                                          : `detail/${data.id ? data.id : data._id}`
                                      }
                                      className="text-decoration-none text-black"
                                    >
                                      {!Array.isArray(col.key)
                                        ? col.isDate
                                          ? dateFormater(data[col.key])
                                          : typeof data[col.key] === 'boolean'
                                          ? data[col.key]
                                            ? 'Active'
                                            : 'Inactive'
                                          : data[col.key]
                                        : col.postFix
                                        ? `${childeView(data, col.key)} ` +
                                          `   ${postFix(
                                            data,
                                            col.postFix?.key,
                                          )}`
                                        : childeView(data, col.key)}
                                    </Link>
                                  </td>
                                )
                              }
                            })}

                            {props?.delete && (
                              <td >
                                <IconTrash
                                  onClick={() => {
                                    props?.delete && props?.delete(data)
                                  }}
                                  color="red"
                                  style={{ cursor: 'pointer' }}
                                  width={20}
                                  height={20}
                                />
                              </td>
                            )}
                          </tr>
                          {isTableExpand && expandedRow?.id === data?.id && (
                            <tr className="template">
                              <td></td>
                              <td colSpan={5}>
                                <Card className="d-flex flex-column gap-10 ">
                                  <div className="text-bold"> Deposited by</div>{' '}
                                  <br />
                                  <div>
                                    {' '}
                                    <span>Name:{data?.deposited_by?.name}</span>
                                    <br />
                                    <span>
                                      Phone:{data?.deposited_by?.phone_number}
                                    </span>
                                    <br />
                                    <span>Reason:{data?.reason}</span>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                )}
              </Table>{' '}
              {total > 0 && (
                <div className="d-flex justify-content-between">
                  <div>
                    <DropdownButton
                      size="sm"
                      variant="outline-secondary"
                      title={dropdownTitle}
                      id="input-group-dropdown-1"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          onPageSizeChange(5)
                          setDropdawnTitle('5 / page')
                        }}
                      >
                        5 / page
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          onPageSizeChange(10)
                          setDropdawnTitle('10 / page')
                        }}
                      >
                        10 / page
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          onPageSizeChange(20)
                          setDropdawnTitle('20 / page')
                        }}
                      >
                        {' '}
                        20/ page
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          onPageSizeChange(30)
                          setDropdawnTitle('30 / page')
                        }}
                      >
                        {' '}
                        30 / page
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          onPageSizeChange(40)
                          setDropdawnTitle('40 / page')
                        }}
                      >
                        {' '}
                        40 / page
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <div className="w-50">
                    <ReactPaginate
                      pageCount={Math.ceil(props?.total / pageSize)}
                      breakLabel="..."
                      nextLabel={'Next'}
                      previousLabel={'Prev'}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={onPageIndexChange}
                      containerClassName="pagination justify-content-end"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item mx-1"
                      nextClassName="page-item"
                      previousLinkClassName={'pagination__link'}
                      nextLinkClassName={'pagination__link'}
                      disabledClassName={'pagination__link--disabled'}
                      activeClassName={'active'}
                      forcePage={pageNumber}
                      breakClassName={'break-me'}
                    />
                  </div>
                </div>
              )}
            </>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default EntityList
