import React, {useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {useGetPublicProviderByIdForCreditQuery} from '../api/bank-api-service';
import styles from '../style.module.css';
import Credit from './credit';

function BankProviderDetail() {
    const {id} = useParams();
    const {data, isLoading} = useGetPublicProviderByIdForCreditQuery(
        id ? id : '',
    );
    const [isCollapsed, setIsCollapsed] = useState(false);
    const onCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> Volunteer Detail</div>
                        <div className='d-flex'>
                            {/* <Link to='/provider' className='text-black'><IconSquareX /></Link> */}
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <div className='border mt-2 mx-2 pt-2'>
                            {/* Name */}
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    Name
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {data?.first_name} {data?.last_name}
                                </div>
                            </div>

                            {/* Gender */}
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    Gender
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {data?.gender}
                                </div>
                            </div>
                            {/* Email */}
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    Email
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {data?.email}
                                </div>
                            </div>
                            {/* Phone */}
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    Phone Number
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {data?.phone_number}
                                </div>
                            </div>
                            {/* Emergency */}
                            {data?.emergency_contact && (
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        {' '}
                                        Emergency Contact
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {data?.emergency_contact}
                                    </div>
                                </div>
                            )}

                            {/* status */}
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    Status{' '}
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {data?.is_active ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}

            <Credit />
        </>
    );
}

export default BankProviderDetail;
