import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {SubmitHandler, useForm} from 'react-hook-form';
import { useLazyGetDeliveryByIdQuery, useUpdateDeliveryCurrentLocationMutation, useUpdateDeliveryStatusMutation } from '../api/delivery-requests-api-service';
import { Button, Card, Form, Image } from 'react-bootstrap';
import SpinnerForPages from 'shared/component/spinner-for-pages';
import styles from './style.module.css'
import GoogleMapContainer from '../components/google_map_container';
import {yupResolver} from '@hookform/resolvers/yup';
import { schema } from '../components/form-validation';
import { VButton } from 'shared/component/button';
import { IconEdit, IconPrinter } from '@tabler/icons';
import CurrentLocation from '../components/current_location';
import { useReactToPrint } from 'react-to-print';



const StatusTypes = ['created', 'confirmed', 'on its way', 'delivered']
export interface DeliveryStatus {
    id: string;
    status: string
}

let reportData:any;

class GenerateInfo extends React.Component {

    render() {
      return (
        <div className="d-flex">
        <div  className="border mt-2 mx-2 pt-2 w-50">
            <h5 className="mx-2">Sender Information</h5>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Name</div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.sender_name}
                </div>
            </div>

            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Phone Number</div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.sender_phone_number}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>
                    Email
                </div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.sender_email}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>Country</div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.sender_address.country}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>City</div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.sender_address.city}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Street Address </div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.sender_address.street_address}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>House No. </div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.sender_address.house_number}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>Tracking ID </div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.tracking_id}
                </div>
            </div>


        </div>

        <div className="border mt-2 mx-2 pt-2 w-50">
            <h5 className="mx-2">Receiver Information</h5>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>Name</div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.reciever_name}
                </div>
            </div>

            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Phone Number</div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.reciever_phone_number}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>
                    Email
                </div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.reciever_email}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Country</div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.reciever_address.country}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2  ${styles.listKey} `}>City</div>
                <div style={{width: "70%"}} className={`p-2  ${styles.list} `}>
                    {reportData?.reciever_address.city}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>Street Address </div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.reciever_address.street_address}
                </div>
            </div>
            <div className="p-2 pt-0 pb-0 d-flex">
                <div style={{width: "30%"}} className={`p-2 ${styles.listKey} `}>House No. </div>
                <div style={{width: "70%"}} className={`p-2 ${styles.list} `}>
                    {reportData?.reciever_address.house_number}
                </div>
            </div>

        </div>
    </div>
      );
    }
  
  }

const DeliveryRequestDetail = () => {
    const [isLoading, setIsLoading] = useState(false)
    

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<DeliveryStatus>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const { id } = useParams();
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isUpdatingLocation, setIsUpdatingLocation] = useState(false)
    

    // const { data, isLoading } = useGetDeliveryByIdQuery(id ? id : '');

    const [getDeliveryDetail, data] =
    useLazyGetDeliveryByIdQuery();

    useEffect(() => {
        setIsLoading(true)

        getDeliveryDetail(id);
        

    }, [])

    useEffect(() => {
       
        reportData = data?.data;
        if(reportData) {
            setIsLoading(false)
        }
        
    }, [data])
    
    const onCollapsed = () => {
        var isExpand = !isCollapsed
        setIsCollapsed(isExpand)
    }

    const [updateDeliveryStatus, {isLoading: updating}] = useUpdateDeliveryStatusMutation();
    const [updateDeliveryCurrentLocation, {isLoading: updatingLocation}] = useUpdateDeliveryCurrentLocationMutation();
    

    const onUpdateStatus: SubmitHandler<DeliveryStatus> = (updatedStatus: DeliveryStatus) => {
        setIsUpdating(true)
        const data = {
            status: updatedStatus.status,
            id: id
        }
        updateDeliveryStatus(data);
        setIsUpdating(false)
        getDeliveryDetail(id)     
    };
    const onUpdateCurrentLocation = (currentLocation: String) => {
       
        setIsUpdatingLocation(true)  
        const data = {
            currentLocation,
            id: id
        }  
        updateDeliveryCurrentLocation(data);   
        getDeliveryDetail(id)     
        setIsUpdatingLocation(false)  

    };

    const componentRef:any = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) :(
                <Card className=" h-100">
                    <Card.Header className="d-flex justify-content-between">
                        <div> Delivery Detail</div>
                        <div className="d-flex ">
                            <Button size="sm" onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <div>
                            <GenerateInfo  ref={componentRef} />
                            <div className='m-2 d-flex flex-row-reverse'> <VButton  size="sm" onClick={handlePrint}>
                            <IconPrinter />
                                 Print
                            </VButton></div>
                            <div className='border mt-3 mx-2 pt-5"'>
                                <h5 className='mx-2 pt-2'>Delivery Information</h5>
                                <div className="p-2 pt-0 pb-0 d-flex">
                                    <div className={`p-2 w-50 ${styles.listKey} `}>Delivery Type </div>
                                    <div className={`p-2 w-50 ${styles.list} `}>
                                        {data?.data?.delivery_type}

                                    </div>
                                </div>
                                <div className="p-2 pt-0 pb-0 d-flex">
                                    <div className={`p-2 w-50 ${styles.listKey} `}>Delivery Summery </div>
                                    <div className={`p-2 w-50 ${styles.list} `}>
                                        {data?.data?.delivery_summery}

                                    </div>
                                </div>
                                {/* <div className="p-2 pt-0 pb-0 d-flex">
                                    <div className={`p-2 w-50 ${styles.listKey} `}>Status </div>
                                    <div className={`p-2 w-50 ${styles.list} `}>
                                        {data?.data?.status}

                                    </div>
                                </div> */}
                                <div className="p-2 pt-0 pb-0 d-flex">
                                    <div className={`p-2 w-50 ${styles.listKey} `}>Delivery Status </div>
                                    <div className={`p-2 w-50 d-flex align-items-center ${styles.list} `}>
                                        
                                      {data?.data?.status && <Form className='d-flex align-items-center'
                                        onSubmit={handleSubmit(onUpdateStatus)}
                                        >
                                        <Form.Group>
                        
                                            
                                            <Form.Select className='align-self-flex-start'
                                            isInvalid={
                                                errors?.status ? true : false
                                            }
                                           
                                            {...register('status')}
                                            >
                                                {/* <option value=''>
                                                    Select Status
                                                </option> */}
                                                {StatusTypes.map((status) => {
                                                    return (
                                                        <option 
                                                            selected={data?.data?.status.toString().trim() == status.trim() ? true : false}
                                                            
                                                            value={status}
                                                            label={status}
                                                        ></option>
                                                    );
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.status?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                       
                                            <VButton 
                                            isLoading={isUpdating}
                                            className='m-2'  type='submit' size="sm" >
                                            <IconEdit />
                                            {' '}
                                            Update
                                            </VButton>

                                           

                                       
                                        </Form>}
                                    </div>
                                </div>
                                <div className="p-2 pt-0 pb-0 d-flex">
                                    <div className={`p-2 w-50 ${styles.listKey} `}>Delivery Current Location </div>
                                    <div className={`p-2 w-50 d-flex align-items-center ${styles.list} `}>

                                       <CurrentLocation currentLocation={data?.data?.current_location} changeLocation={onUpdateCurrentLocation}/>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-2 mb-2 p-2 overflow-hidden'>
                                <GoogleMapContainer
                                
                                    sender_latitude={data?.data?.sender_location.latitude}
                                    sender_longitude={data?.data?.sender_location.longitude}
                                    reciever_latitude={data?.data?.reciever_location.latitude}
                                    reciever_longitude={data?.data?.reciever_location.longitude}
                                />
                            </div>
                        </div>

                    </Card.Body>
                </Card>
            )}
        </>
    )
}

export default DeliveryRequestDetail