import '@reach/combobox/styles.css';

import React, { useEffect, useState } from 'react';
import { VButton } from 'shared/component/button';
import { IconEdit } from '@tabler/icons';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';

import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxPopover,
} from '@reach/combobox';
import { result, set } from 'lodash';

function CurrentLocation(props) {

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (val: string) => {
        setValue(val, false);
        clearSuggestions();
        const results = await getGeocode({ address: val });
        const { lat, lng } = await getLatLng(results[0]);
        // startingPoint({lat, lng});
        // startingAddress(val);
    };

    useEffect(() => {
        clearSuggestions()
       setValue(props.currentLocation)
       
      
    }, [props.currentLocation]);

    
    return (
        <div className="d-flex align-items-center">
            <Combobox onLoad={() => clearSuggestions()} onSelect={handleSelect} className='width-70%'>
                <ComboboxInput
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className='combobox-input input-sm'
                    placeholder='Select current location'
                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === 'OK' &&
                            data.map(({ place_id, description }) => (
                                <ComboboxOption
                                    key={place_id}
                                    value={description}
                                />
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
            <VButton
                onClick={() => props.changeLocation(value)}
                className='m-2' type='submit' size="sm" >
                <IconEdit />
                {' '}
                Update
            </VButton>
        </div>
    );
}

export default CurrentLocation;
