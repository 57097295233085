import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import Select from 'react-select';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';

import {
    useCreateAwardMutation,
    useDeleteAwardMutation,
    useLazyGenerateWinnersQuery,
    useUpdateAwardMutation,
} from '../api/award-api-service';
import {schema} from './form-validation';

export interface AwardFormProps {
    viewMode: string;
    title: string;
    selectedItem?: any;
    selectedItemLoading?: boolean;
}
function AwardForm(props: AwardFormProps) {
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue,
    } = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [addNewAward, {isLoading: creating}] = useCreateAwardMutation();
    const [updateAward, {isLoading: updating}] = useUpdateAwardMutation();
    const [deleteAward, {isLoading: deleting}] = useDeleteAwardMutation();
    const [selectedValue, setSelectedValue] = useState<any[]>([]);
    const [dirty, setDirty] = useState<boolean>(false);
    const awardForList = [
        {
            value: 'taxi_driver',
            label: 'Volunteers',
        },
        {
            value: 'taxi_passenger',
            label: 'Youths',
        },
        // {
        //     value: 'truck_driver',
        //     label: 'Truck Provider',
        // },
        // {
        //     value: 'truck_passenger',
        //     label: 'Truck Customer',
        // },
    ];

    const [generate, result] = useLazyGenerateWinnersQuery();
    const onCreate = (newAward: any) => {
        const sendData = {
            ...newAward,
            award_for: selectedValue.map((v) => v.value),
        };
        if (selectedValue.length > 0) addNewAward(sendData);
    };

    const onUpdate = (updatedAward: any) => {
        const sendData = {
            ...updatedAward,
            award_for: selectedValue.map((v) => v.value),
        };
        if (selectedValue.length > 0) updateAward(sendData);
    };

    // const onDelete= () => {
    //   deleteAward(props.selectedItem.id);
    // }

    const handleOnChange = (e) => {
        setDirty(true);
        setSelectedValue(e);
    };
    useEffect(() => {
        if (Object.keys(errors).length > 0) setDirty(true);
    }, [errors]);
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    useEffect(() => {
        if (props.selectedItem) {
            const selectedData = {
                ...props.selectedItem,
                start_date: formatDate(props.selectedItem?.start_date),
                end_date: formatDate(props.selectedItem?.end_date),
            };
            reset(selectedData);
        }
        setIsLoading(props?.selectedItemLoading);
    }, [props.selectedItem, reset, props?.selectedItemLoading]);

    function generateAward() {
        return props.selectedItem?.is_active &&
            formatDate(props.selectedItem?.end_date) >= formatDate(new Date())
            ? false
            : true;
    }
    const getLabel = (value) => {
        switch (value) {
            case 'taxi_driver':
                return 'Taxi Volunteer';
            case 'taxi_passenger':
                return 'Taxi Youth';
            case 'truck_driver':
                return 'Truck Volunteer';
            case 'truck_passenger':
                return 'Truck Youth';
        }
    };
    useEffect(() => {
        if (props.selectedItem?.award_for?.length > 0) {
            setSelectedValue(() => {
                return props.selectedItem?.award_for.map((element) => {
                    return {
                        value: element,
                        label: getLabel(element),
                    };
                });
            });
        }
    }, [props.selectedItem?.award_for]);

    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> {props.title}</div>
                        <div className='d-flex gap-4'>
                            {!isCollapsed && (
                                <Button
                                    size='sm'
                                    onClick={() =>
                                        generate(props.selectedItem.id)
                                    }
                                    disabled={generateAward()}
                                >
                                    Generate
                                </Button>
                            )}
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form
                            onSubmit={
                                props.viewMode === 'detail'
                                    ? handleSubmit(onUpdate)
                                    : handleSubmit(onCreate)
                            }
                        >
                            <div className='d-flex mb-4'>
                                <div className='w-50 '>
                                    <Form.Group controlId='formFirstName'>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Title
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.title ? true : false
                                            }
                                            {...register('title')}
                                            type='text'
                                            placeholder='enter title '
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.title?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Award For
                                            </Form.Label>
                                        </div>

                                        <Select
                                            isMulti
                                            isClearable
                                            isSearchable={false}
                                            className='react-dropdown'
                                            classNamePrefix='dropdown'
                                            options={awardForList}
                                            value={selectedValue}
                                            onChange={handleOnChange}
                                        />

                                        <p style={{color: 'red'}}>
                                            {dirty &&
                                                selectedValue.length === 0 &&
                                                'Award For is required'}
                                        </p>

                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.roles &&
                                                errors?.roles[0]?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50 '>
                                    <Form.Group
                                        className='mb-3'
                                        controlId='formStartDate'
                                    >
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Start Date
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.start_date
                                                    ? true
                                                    : false
                                            }
                                            {...register('start_date')}
                                            type='date'
                                            // min={new Date().toISOString().split('T')[0]}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.start_date?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='w-50 mx-4'>
                                    <Form.Group
                                        className='mb-3'
                                        controlId='formEndDate'
                                    >
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                End Date
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.end_date ? true : false
                                            }
                                            {...register('end_date')}
                                            type='date'
                                            // min={new Date().toISOString().split('T')[0]}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.end_date?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='w-50'>
                                <Form.Group controlId='formLastName'>
                                    <div className='form-group required'>
                                        <Form.Label className='control-label'>
                                            Description
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        as='textarea'
                                        rows={3}
                                        isInvalid={
                                            errors?.description ? true : false
                                        }
                                        {...register('description')}
                                        type='text'
                                        placeholder='enter description'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors?.description?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className='d-flex mt-5'>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'detail'}
                                    variant='primary'
                                    isLoading={creating}
                                >
                                    {' '}
                                    <IconDeviceFloppy /> Save
                                </VButton>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'new'}
                                    className='mx-2'
                                    variant='warning'
                                    isLoading={updating}
                                >
                                    {' '}
                                    <IconEdit />
                                    Update
                                </VButton>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default AwardForm;
