import React, {useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import SpinnerForPages from 'shared/component/spinner-for-pages';
import {CollectionQuery} from 'shared/model/collection.model';

import {IconPremiumRights} from '@tabler/icons';

import {
    useGetDailyRequestsQuery,
    useGetIncomeByVehicleTypeQuery,
    useGetNewIncomeQuery,
    useGetTotalDriverQuery,
    useGetTotalIncomeQuery,
    useGetTotalOwnersQuery,
    useGetTotalPassengersQuery,
    useGetTotalRequestsQuery,
    useGetTotalTodayPassengersQuery,
    useGetTotalTransactionQuery,
    useLazyGetAllDriversTotalCreditQuery,
} from '../api/dashboard-api-services';
import AreaCharts from './area-chart';

export default function Report() {
    const date = new Date();
    const toDayQueary: CollectionQuery = {
        filter: [
            [
                {
                    field: 'createdAt',
                    value: `${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()}`,
                    type: 'date',
                    operator: '=',
                },
            ],
        ],
    };

    const {data: newPassenger, isLoading: newPassengerLoading} =
        useGetTotalTodayPassengersQuery(toDayQueary);
    const [getAllDriversTotalCredit, totalCredit] =
        useLazyGetAllDriversTotalCreditQuery();

    const {data: newIncome, isLoading: newIncomeLoading} =
        useGetNewIncomeQuery(toDayQueary);

    const {data: todaysRequests, isLoading: todaysRequestsLoading} =
        useGetDailyRequestsQuery(toDayQueary);

    const {data: totalRequest, isLoading: totalRequestLoading} =
        useGetTotalRequestsQuery('');
    const {data: totalOwners, isLoading: totalOwnersLoading} =
        useGetTotalOwnersQuery('');

    const {data: totalPassenger, isLoading: passengerLoading} =
        useGetTotalPassengersQuery('');

    const {data: totalDriver, isLoading: totalDriverLoading} =
        useGetTotalDriverQuery('');

    const {data: totalIncome, isLoading: totalIncomeLoading} =
        useGetTotalIncomeQuery('');

    const {data: inComeByVehicleType, isLoading: incomByVehicleTypeLoading} =
        useGetIncomeByVehicleTypeQuery('');

    const {data: totalTransaction} = useGetTotalTransactionQuery('');

    function activeDriver(): number {
        if (totalDriver) {
            const active = totalDriver.filter((d) => d.is_active === true);
            return active.length > 0 ? active[0]?.count : 0;
        }
        return 0;
    }

    function inActiveDriver(): number {
        if (totalDriver) {
            const inActive = totalDriver.filter((d) => d.is_active === false);
            return inActive.length > 0 ? inActive[0]?.count : 0;
        }
        return 0;
    }
    useEffect(() => {
        getAllDriversTotalCredit(null);
    }, []);
    const convertMonth = (element: any) => {
        const month = element.month;
        switch (month) {
            case 1:
                return {
                    ...element,
                    month: 'Jan',
                };

            case 2:
                return {
                    ...element,
                    month: 'Feb',
                };

            case 3:
                return {
                    ...element,
                    month: 'Mar',
                };

            case 4:
                return {
                    ...element,
                    month: 'Apr',
                };

            case 5:
                return {
                    ...element,
                    month: 'May',
                };

            case 6:
                return {
                    ...element,
                    month: 'Jun',
                    total: +element.truck + +element.taxi,
                };

            case 7:
                return {
                    ...element,
                    month: 'Jul',
                };

            case 8:
                return {
                    ...element,
                    month: 'Aug',
                };

            case 9:
                return {
                    ...element,
                    month: 'Sep',
                };

            case 10:
                return {
                    ...element,
                    month: 'Oct',
                };
            case 11:
                return {
                    ...element,
                    month: 'Nov',
                };
            default:
                return {
                    ...element,
                    month: 'Dec',
                };
        }
    };
    return (
        <React.Fragment>
            {newIncomeLoading || newPassengerLoading || totalDriverLoading ? (
                <SpinnerForPages />
            ) : (
                <>
                    <h3 className='mt-3'>Daily Report</h3>

                    <div className='d-flex justify-content-between'>
                        <div
                            className='card rounded background_primary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title'>New Youths</h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {newPassenger?.count}
                                    </h4>
                                    <i className='bx bx-user text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>

                        {/*  Active providers */}
                        <div
                            className='card rounded background_primary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title'>Active Volunteers</h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {activeDriver()}
                                    </h4>
                                    <i className='bx bx-car text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>

                        {/*  Inactive providers */}
                        <div
                            className='card rounded background_primary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title'>
                                    Inactive Volunteers
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {inActiveDriver()}
                                    </h4>
                                    <i className='bx bx-car text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>

                        {/*  Daily income */}
                        {/* <div
                            className='card rounded background_primary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title'>Daily Income</h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {newIncome?.total} ETB
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div> */}

                        {/*  Daily Requests */}
                        <div
                            className='card rounded background_primary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title'>Daily Requests </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                     {todaysRequests?.count}
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className='mt-3'>Summary Report</h3>
                    <div className='d-flex justify-content-between'>
                        {/*  Total customers */}
                        <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                    Total Youths
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {totalPassenger?.count}
                                    </h4>
                                    <i className='bx bx-user text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>

                        {/*  Total providers */}
                        <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                    Total Volunteers
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {' '}
                                        {inActiveDriver() + activeDriver()}
                                    </h4>
                                    <i className='bx bx-car text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>

                        {/*  Summary Income */}
                        {/* <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                    Summary Income
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {' '}
                                        {new Intl.NumberFormat().format(
                                            Math.floor(totalIncome?.total),
                                        )}{' '}
                                        ETB
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div> */}

                        {/*  Total transaction */}
                        {/* <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                    Total Transaction
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                        {new Intl.NumberFormat().format(
                                            totalTransaction?.total_transaction,
                                        )}{' '}
                                        ETB
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div> */}
                        {/*  Total Requests */}
                        <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                Total Requests
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                       {totalRequest?.count}
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>
                        {/*  Total Owners */}
                        <div
                            className='card rounded background_secondary shadow'
                            style={{width: '250px', height: '110px'}}
                        >
                            <div className='card-body'>
                                <h6 className='card-title color_primary'>
                                Total Owners
                                </h6>
                                <div className='d-flex justify-content-between '>
                                    <h4 className='text-white'>
                                      {totalOwners?.count}
                                    </h4>
                                    <i className='bx bx-money text-white font_report_icon'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {totalCredit?.isSuccess && totalCredit?.data && (
                        <Alert
                            variant='light'
                            className='mt-4 shadow-sm border background_primary'
                        >
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center gap-2'>
                                    <IconPremiumRights
                                        color='white'
                                        size={80}
                                    />
                                    <div>
                                        <div className='fs-4 text-black text-capitalize'>
                                            All drivers total credit
                                        </div>
                                        <div className='fs-5 text-white'>
                                            {new Intl.NumberFormat().format(
                                                totalCredit?.data?.total,
                                            )}
                                            ETB
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Alert>
                    )} */}
                    {/* <div
                        style={{
                            width: '100%',
                            height: '320px',
                            marginTop: '22px',
                        }}
                    >
                        <AreaCharts
                            items={
                                inComeByVehicleType &&
                                inComeByVehicleType?.map((element) =>
                                    convertMonth(element),
                                )
                            }
                        />
                    </div> */}
                </>
            )}
        </React.Fragment>
    );
}
