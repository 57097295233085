import MessagingServices from "shared/messaging-services";
import { collectionQueryBuilder } from "shared/model/collection-query-builder";
import { dashboardApiService } from "store/dashboardApi";

import { render } from "@testing-library/react";

import { ReviewEndpoint } from "./review-endpoint";

const reviewApiService = dashboardApiService.injectEndpoints({
  endpoints(builder) {
    return {
      listReview: builder.query({
        query: (query: any) => ({
          url: `${ReviewEndpoint.list}`,
          method: "get",
          params: collectionQueryBuilder(query),
        }),
      }),
      detailReview: builder.query({
        query: (id: string) => ({
          url: `${ReviewEndpoint.detail}/${id}`,
          method: "get",
          headers: "",
        }),
      }),
      deleteReview: builder.mutation({
        query: (id: string) => ({
          url: `${ReviewEndpoint.delete}/${id}`,
          method: "delete",
          headers: "",
        }),
        async onQueryStarted(config, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            render(
              <MessagingServices
                message="Review deleted Successfully"
                show={true}
                type={"success"}
              />
            );
          } catch (error) {
            render(
              <MessagingServices
                message="Something went wrong review not deleted"
                show={true}
                type={"error"}
              />
            );
          }
        },
      }),
    };
  },
});

export const {
  useLazyListReviewQuery,
  useDetailReviewQuery,
  useDeleteReviewMutation,
} = reviewApiService;
