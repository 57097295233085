import {Configuration} from 'features/model/Configurtion';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {VButton} from 'shared/component/button';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';

import {
    useListConfigurationQuery,
    useUpdateConfigurationMutation,
} from '../api/configuration-api-services';
import {schema} from './form-validation';

function ConfigurationForm() {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<Configuration>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const [isCollapsed, setIsCollapsed] = useState(false);

    const {data, isLoading} = useListConfigurationQuery('');
    const [updateConfiguration] = useUpdateConfigurationMutation();

    const onUpdate: SubmitHandler<Configuration> = (
        updatedConfiguration: Configuration,
    ) => {
        updateConfiguration(updatedConfiguration);
    };

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };
    useEffect(() => {
        reset(data);
    }, [data, reset]);
    return (
        <>
            {isLoading ? (
                ''
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div>Setting Configuration</div>
                        <div className='d-flex'>
                            {''}
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form onSubmit={handleSubmit(onUpdate)}>
                            <div className='d-flex mb-4'>
                                <div className='w-50 '>
                                    <Card>
                                        <Card.Header>Radius</Card.Header>
                                        <Card.Body>
                                            <Form.Group className='mb-2'>
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        Taxi
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.radius?.taxi
                                                            ? true
                                                            : false
                                                    }
                                                    {...register('radius.taxi')}
                                                    type='number'
                                                    min={1}
                                                    placeholder='enter radius for taxi'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.radius?.taxi
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
{/* 
                                            <Form.Group className='mb-2'>
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        Truck
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.radius?.truck
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'radius.truck',
                                                    )}
                                                    type='number'
                                                    min={1}
                                                    placeholder='enter radius for truck'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.radius?.truck
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group> */}
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Card>
                                        <Card.Header>Award</Card.Header>
                                        <Card.Body>
                                            <Form.Group className='mb-2'>
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        Taxi Point
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.award_point
                                                            ?.taxi
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'award_point.taxi',
                                                    )}
                                                    type='number'
                                                    min={1}
                                                    placeholder='enter award'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.award_point
                                                            ?.taxi?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            {/* <Form.Group className='mb-2'>
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        Truck Point
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.award_point
                                                            ?.taxi
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'award_point.truck',
                                                    )}
                                                    type='number'
                                                    min={1}
                                                    placeholder='enter award'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.award_point
                                                            ?.truck?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            {/* <Form.Group className='mb-2 w-50'>
                                <div className='form-group required'>
                                    <Form.Label className='control-label'>
                                        Borrow Limit
                                    </Form.Label>
                                </div>
                                <Form.Control
                                    isInvalid={
                                        errors?.borrow_limit ? true : false
                                    }
                                    {...register('borrow_limit')}
                                    type='number'
                                    min={1}
                                    placeholder='enter  borrow limit'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors?.borrow_limit?.message}
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            <div>
                                {' '}
                                <VButton type='submit' variant='warning'>
                                    <IconEdit /> Update
                                </VButton>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default ConfigurationForm;
