import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { schema } from './form-validation-items'
type AwardItemFormProps = {
  selectedAwardId: string
  onSave: (credit: any) => void
}
function AwardItemForm(props: AwardItemFormProps) {
  const [showModal, setShowModal] = useState(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const onAddAmount: SubmitHandler<any> = (credit: any) => {
    const sendData = {
      award_type_id: props.selectedAwardId,
      amount: credit.amount,
      title: credit.title,
      description: credit.description,
    }
    props.onSave(sendData)
    setShowModal(false)
  }

  return (
    <>
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Award Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onAddAmount)}>
            <div className="d-flex mb-4">
              <div className="w-50 ">
                <Form.Group controlId="formFirstName">
                  <div className="form-group required">
                    <Form.Label className="control-label">Title</Form.Label>
                  </div>
                  <Form.Control
                    isInvalid={errors?.title ? true : false}
                    {...register('title')}
                    type="text"
                    placeholder="enter title "
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.title?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="w-50 mx-4">
                <Form.Group controlId="formLastName">
                  <div className="form-group required">
                    <Form.Label className="control-label">
                      Description
                    </Form.Label>
                  </div>
                  <Form.Control
                    isInvalid={errors?.description ? true : false}
                    {...register('description')}
                    type="text"
                    placeholder="enter description"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.description?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>

              <Form.Control
                isInvalid={errors?.amount ? true : false}
                {...register('amount')}
                type="number"
                defaultValue={1}
                placeholder="enter amount"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.amount?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex flex-row-reverse">
              <Button size="sm" type="submit">
                save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AwardItemForm
