import MessagingServices from 'shared/messaging-services';
import { dashboardApiService } from 'store/dashboardApi';

import { render } from '@testing-library/react';
import { AddressEndpoint } from './address-endpoint';
import { collectionQueryBuilder } from 'shared/model/collection-query-builder';

const AddressApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            addAddress: builder.mutation({
                query: (newAddress: any) => ({
                    url: `${AddressEndpoint.addAddress}`,
                    method: 'post',
                    data: newAddress,
                }),
                async onQueryStarted(id, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Address Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            getAddresses: builder.query({
                query: (query?: any) => ({
                    url: `${AddressEndpoint.getAddresses}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),

            getAddressById: builder.query({
                query: (id) => ({
                    url: `${AddressEndpoint.getAddress}/${id}`,
                    method: 'get',
                   
                }),
               
            }),
            deactivateAddress: builder.query({
                query: (id) => ({
                    url: `${AddressEndpoint.deactivateAddress}/${id}`,
                    method: 'get',
                   
                }),
                async onQueryStarted(config, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message={`Address ${data.active ? "Activated" : "Deactivated"} successfully`}
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                            message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
               
            }),

        }
    }
})

export const {
    useAddAddressMutation,
    useLazyGetAddressesQuery,
    useLazyGetAddressByIdQuery,
    useLazyDeactivateAddressQuery

} = AddressApiService