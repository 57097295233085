export const ConfigurationEndpoint = {
    // Setting configuration
    list: `settings/get-setting`,
    update: `settings/update-setting`,

    // Vehicle category configuration
    listCategory: `categories/get-categories`,
    detailCategory: `categories/get-category`,
    createCategory: `categories/create-category`,
    updateCategory: `categories/update-category`,
    deleteCategory: `categories/delete-category`,
    activateCategory: `categories/activate-category`,
    deactivateCategory: `categories/block-category`,
  
};
