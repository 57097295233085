import {useParams} from 'react-router-dom';

import {useGetProviderByIdQuery} from '../api/provider-api-services';
import DocumentFrom from '../component/document-from';
import ProviderForm from '../component/provider-form';

function ProviderDetail() {
    const {id} = useParams();
    const {data, isLoading, refetch} = useGetProviderByIdQuery(id ? id : '');
    return (
        <>
            <ProviderForm
                title='Volunteer Detail'
                viewMode='detail'
                selectedItem={data}
                selectedItemLoading={isLoading}
            />

            <DocumentFrom provider={data} />
        </>
    );
}

export default ProviderDetail;
