import React, { useEffect, useState } from "react";
import EntityList from "shared/entity/entity-list";
import { EntityConfiguration } from "shared/entity/models";
import { CollectionQuery } from "shared/model/collection.model";

import { useLazyListReviewQuery } from "../api/review-api-services";

function ReviewList() {
  const [request, setRequest] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["reviewer", "driver"],
    orderBy: [
      {
        field: "createdAt",
        direction: "desc",
      },
    ],
    // searchFrom: ["name", "email", "phone_number"],
  });

  const [getData, result] = useLazyListReviewQuery();
  const [items, setItems] = useState(result?.data?.items);
  const [total, setTotal] = useState(result?.data?.total);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(request, false);
  }, [request, getData]);
  useEffect(() => {
    setItems(result?.data?.items);
    setTotal(result?.data?.total);
    setLoading(result?.isLoading);
  }, [result]);

  let config: EntityConfiguration = {
    rootUrl: "/review",
    title: "Reviews List",
    detailUrl: "/review/detail",
    hasDetail: false,
    showNewButton: false,
    visibleColumn: [
      //   { key: "passenger", name: "Name" },
      //   { key: "phone_number", name: "Phone Number" },
      {
        key: ["reviewer", "name"],
        name: "Youth",
        postFix: { key: ["reviewer", "phone_number"] },
      },
      {
        key: ["driver", "first_name"],
        name: "Volunter",
        postFix: { key: ["driver", "phone_number"] },
      },
      { key: "description", name: "Review" },
      { key: "rate", name: "Rate" },
      { key: "created_at", name: "Received At", isDate: true },
    ],
  };

  const onSearch = (req: any) => {
    setRequest((prev) => {
      return { ...prev, search: req };
    });
  };
  const onPagination = (req: any) => {
    setRequest((prev) => {
      return { ...prev, skip: req.skip, top: req.top };
    });
  };
  const onFilter = (req: any) => {
    setRequest((prev) => {
      return { ...prev, filter: req };
    });
  };

  return (
    <EntityList
      itemsLoading={loading}
      items={items}
      total={total}
      config={config}
      search={onSearch}
      pagination={onPagination}
      filter={onFilter}
    />
  );
}

export default ReviewList;
