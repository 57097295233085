import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAwardByIdQuery } from '../api/award-api-service'
import AwardForm from '../component/award-form'
import AwardItemList from './award-item-list'
import AwardWinnersList from './award-winner-list'
function AwardDetail() {
  const { id } = useParams()
  const { data, isLoading } = useGetAwardByIdQuery(id ? id : '')
  return (
    <>
      <AwardForm
        title="Award Detail"
        viewMode="detail"
        selectedItem={data}
        selectedItemLoading={isLoading}
      />
      <AwardItemList />
      <AwardWinnersList />
    </>
  )
}

export default AwardDetail
