import * as yup from 'yup';

const phoneRegExp =
    /^([+][2][5][1]([7]|[9])[0-9]{8}$)|[+][2][5][1][9][0-9]{8}$/;

export const schema = yup
    .object({
        id: yup.string(),
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description  is required'),
        amount: yup.number().min(1).required('Amount is required'),
    })
    .required();
