import React, { useState, useEffect } from 'react';
import Header from 'shared/layout/header';

import Navigation from '../../../shared/layout/navigation';
import AddressInput from '../component/addresses-input';
import { CollectionQuery } from 'shared/model/collection.model';
import { EntityConfiguration } from '../../../shared/entity/models';
import { Card } from 'react-bootstrap';
import EntityList from 'shared/entity/entity-list';
import { useLazyGetAddressesQuery } from '../api/address-api-service';


function Addresses() {
    function handle_burger_btn(): void {
        const sidebar = document.getElementById('sidebar');
        sidebar?.classList.toggle('close');
    }

    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        // filter: [
        //     [
        //         {
        //             field: 'name',
        //             value: "id",
        //             operator: '=',
        //         },
        //     ],
        // ],
      
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['name'],
    });



    let config: EntityConfiguration = {
        rootUrl: 'addresses',
        title: 'Predefined Addresses',
        showNewButton: false,
        // detailUrl: 'address-detail',
        visibleColumn: [
            { key: 'name', name: 'Name' },

        ],
        filter: [
            [
                {
                    field: 'active',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'active',
                    value: 'false',
                    operator: '=',
                    name: 'Deactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return { ...prev, search: req };
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return { ...prev, skip: req.skip, top: req.top };
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {
                ...prev,
                filter: [
                    ...req,
                    // [
                    //     {
                    //         field: 'vehicle',
                    //         value: "id",
                    //         operator: '=',
                    //     },
                    // ],
                ],
            };
        });
    };
    const [getAddresses, result] = useLazyGetAddressesQuery();
    const [items, setItems] = useState([])
    useEffect(() => {
        getAddresses(request);
    }, [request, getAddresses])

    useEffect(() => {
        setItems(result?.data?.items);
    }, [result])
   

    const loadOnAdd = () =>  {
        getAddresses(request);
    }
    return (
        <React.Fragment>
           
                    <div className='p-2'>
                        <div className=''>
                            <Card>

                                <AddressInput loadOnAdd={loadOnAdd} />
                                <div className='m-3'>

                                <EntityList
                                    itemsLoading={false}
                                    pagination={onPagination}
                                    search={onSearch}
                                    items={items}
                                    config={config}
                                    total={items ? result?.data?.total : 0}
                                    filter={onFilter}
                                />
                                </div>
                            </Card>
                        </div>
                        {/* <ConfigurationForm /> */}

                    </div>
               
        </React.Fragment>
    );
}

export default Addresses;
