import React, {useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

import {render} from '@testing-library/react';

import {
    useAddCreditFromBankMutation,
    useGetBankCreditQuery,
} from '../api/bank-api-service';
import CreditForm from '../component/credit-form';
import CreditHistory from './credit-history';

type CreditFormProps = {
    selectedDriver: any;
};
function Credit() {
    const {id} = useParams();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const {data, isLoading, refetch} = useGetBankCreditQuery(id ? id : '');
    const [add] = useAddCreditFromBankMutation();
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const onOpenModal = () => {
        render(
            <CreditForm
                selectedDriverId={id!}
                onSave={async (credit: any) => {
                    await add(credit);
                    refetch();
                }}
            />,
        );
    };
    return (
        <Card className='mt-2'>
            <Card.Header className='d-flex justify-content-between'>
                <div>Credit Detail</div>
                {!isCollapsed && (
                    <div
                        style={{color: 'green', font: 'bold', fontSize: '24px'}}
                    >
                        {' '}
                        {data?.balance} Birr
                    </div>
                )}
                <div></div>
                <div className='d-flex gap-4'>
                    <Button size='sm' onClick={onCollapsed}>
                        {isCollapsed ? 'Expand' : 'Collapse'}
                    </Button>

                    {!isCollapsed && (
                        <Button size='sm' onClick={onOpenModal}>
                            add credit
                        </Button>
                    )}
                </div>
            </Card.Header>
            <Card.Body className={isCollapsed ? 'd-none' : ''}>
                <CreditHistory driverId={id} />
            </Card.Body>
        </Card>
    );
}

export default Credit;
