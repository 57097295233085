import { IconPlus, IconSearch, IconTrash } from '@tabler/icons'
import { VButton } from 'shared/component/button';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Table,
    DropdownButton,
    Dropdown,
    FormControl,
    InputGroup,

    Form,
} from 'react-bootstrap'
import { useAddAddressMutation } from '../api/address-api-service';



const AddressInput = (props: any) => {

    const [isValid, setIsValid] = useState(false);
    const [name, setName] = useState('')
    const [latitude, setLat] = useState(0)
    const [longitude, setLng] = useState(0)
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete()

    const handleSelect = async (val: string) => {
        setValue(val, false)
    
        clearSuggestions()

        const results = await getGeocode({
            address: val,
        })
        const { lat, lng } = await getLatLng(results[0])
        setName(val);
        setLat(lat);
        setLng(lng);
      setIsValid(true)
    }

    const [newAddress, { isLoading: adding }] = useAddAddressMutation();

    const addNewAddress = async () => {
        const data = {
            name: name,
            lat: latitude,
            lng: longitude 
        }
       await newAddress(data);
        props.loadOnAdd();
    }


    return <div

        className=' w-100 m-2'
    >
        <InputGroup className='d-flex justify-content-center w-100'>
            <Combobox onSelect={handleSelect} className='w-75'>
                <ComboboxInput
                    style={{
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                        padding: '10px'
                    }}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                        setIsValid(false)
                    }}
                    className='form-control'
                    placeholder="Add predefined addresses"
                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === 'OK' &&
                            data.map(({ place_id, description }) => (
                                <ComboboxOption key={place_id} value={description} />
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
            <VButton
            disabled={!isValid}
            onClick={addNewAddress}
            size="sm">
                <IconPlus />
                Add
            </VButton>
        </InputGroup>
    </div>
}

export default AddressInput