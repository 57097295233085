export const baseUrl = 'https://drummroll.org/backend/api/';

//export const baseUrl= 'http://localhost:5000/api/';
export const baseUrlForFile= 'https://drummroll.org/backend';
export const firebaseApiKey = 'AIzaSyASGOelsR0drKQi7srj0egqMqd3JRtbW8w';
export const firebaseDatabaseUrl = 'https://drummroll-303e6-default-rtdb.firebaseio.com';
export const firebaseProjectId = "drummroll-303e6";
export const firebaseAppId = "1:505969589219:android:441e54d3e91e150dffbed6";
// export const googleMapApiKey = "AIzaSyCl9I3TEfV1PQx7k-6Jqc8VxEFfN1lcWY4";
export const googleMapApiKey = "AIzaSyD-zQgILyIC2sEcHn_cNrnGfbKICSC2OH0";
export const  webSocketUrl = 'wss://drummroll.org';
export const domain = "https://drummroll.org/";
