import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useLazyDeactivateAddressQuery, useLazyGetAddressByIdQuery } from '../api/address-api-service';
import { Card } from 'react-bootstrap';
import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators';
import SpinnerForPages from 'shared/component/spinner-for-pages';
import { IconPrinter } from '@tabler/icons';
import { VButton } from 'shared/component/button';


const AddressDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(false);
    const { id } = useParams();

    const [getAddress, data] =
    useLazyGetAddressByIdQuery();

    const [deactivateAddress, result] =
    useLazyDeactivateAddressQuery();

    useEffect(() => {

        getAddress(id);
    }, [result])


    const toggleAddressStatus = () => {
       
        deactivateAddress(id);
        setActive(result?.data?.active);

    }

    useEffect(() => {
       
      setActive(data?.data?.active)
        
    }, [data])

    return !(data?.data) ? <SpinnerForPages /> : <div>
        <Card className='p-2'>
            <div className='d-flex justify-content-between'>
                <div>{data?.data.name}</div>

                <VButton onClick={toggleAddressStatus} size="sm">
                            {/* <Icon /> */}
                               {active ? 'De-activate': "Activate"}
                            </VButton>
                
            </div>
            
        </Card>
    </div>
}

export default AddressDetail;